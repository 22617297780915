import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
} from "@mui/material";
import axios from "axios";
import { Link, useSearchParams , useLocation } from "react-router-dom";
import { FileDownload, Preview } from "@mui/icons-material";
import { API_URL } from "../../config";
import { httpClient } from "../utils/HttpClient";

const defaultPagination = {
  pageIndex: 0,
  pageSize: 10,
};

const MyAllEstimateDataTable = () => {
  //manage our own state for stuff we want to pass to the API
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(10000);
  let [searchParams, setSearchParams] = useSearchParams();
  const [initialState, setInitialState] = useState(false);
  const location = useLocation()
  const thisLink = `${location.pathname}${location.search}`

  useEffect(() => {
    const where = searchParams.get("columnFilters");
    const search = searchParams.get("globalFilter");
    const sort = searchParams.get("sorting");
    const page = searchParams.get("pagination");

    if (where) {
      setColumnFilters(JSON.parse(where));
    }
    if (search) {
      setGlobalFilter(search);
    }
    if (sort) {
      setSorting(JSON.parse(sort));
    }
    if (page) {
      setPagination(JSON.parse(page));
      // table.setPageIndex(JSON.parse(page).pageIndex)
    }
    setInitialState(true);
  }, []);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState(defaultPagination);

  const fetchData = async () => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }
    if (!initialState) {
      return null;
    }

    const url = new URL("/api/jobs/new-my-all-estimate", API_URL);
    url.searchParams.set("page", `${pagination.pageIndex}`);
    url.searchParams.set("limit", `${pagination.pageSize}`);
    url.searchParams.set("where", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("globalFilter", globalFilter ?? "");
    url.searchParams.set("sort", JSON.stringify(sorting ?? []));

    setSearchParams({
      ...(columnFilters.length > 0 && {
        columnFilters: JSON.stringify(columnFilters),
      }),
      ...(globalFilter !== "" && {
        globalFilter,
      }),
      ...(sorting.length > 0 && {
        sorting: JSON.stringify(sorting),
      }),
      ...((pagination.pageIndex !== defaultPagination.pageIndex ||
        pagination.pageSize !== defaultPagination.pageSize) && {
        pagination: JSON.stringify(pagination),
      }),
    });

    try {
      const response = await axios.get(url.href);
      const json = response.data;
      setData(json.rows);
      setRowCount(json.count);
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };



  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "No.",
        enableEditing: false,
        size: 10,
        grow: false,
        maxSize: 10,
      },
      {
        accessorKey: "desc",
        header: "รายละเอียด",
        enableEditing: false,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            {row.original.JobImages && row.original.JobImages.length > 0 ? (
              <img
                alt="avatar"
                height={"50px"}
                src={`${API_URL}/uploads/images/${row.original.JobImages[0].fileName}`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "https://placehold.co/50?text=not_found";
                }}
                loading="lazy"
                style={{ height: "50px" }}
              />
            ) : (
              <img
                alt="avatar"
                height={"50px"}
                src={`https://placehold.co/50?text=image`}
                loading="lazy"
                style={{ height: "50px" }}
              />
            )}

            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorKey: "JobType.name",
        header: "ประเภทงาน",
        enableSorting: false,
      },
      {
        accessorKey: "JobType.JobGroup.name",
        header: "กลุ่มงาน",
        size: 100,
        enableSorting: false,
      },
      {
        accessorKey: "Branch.name",
        header: "สาขา",
        size: 100,
        grow: false,
        maxSize: 100,
        enableSorting: false,
      },
      {
        accessorKey: "fullName",
        header: "ผู้แจ้ง",
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            {row.original.User.firstName} {row.original.User.lastName}
          </>
        ),
      },
      {
        accessorKey: "status",
        header: "สถานะ",
        filterVariant: "select",
        filterSelectOptions: [
          "งานใหม่",
          "งานใหม่(เปลี่ยนหมวด)",
          "ผู้ประเมินตรวจรับงาน",
          "งานโครงการ",
          "ส่งช่างภายใน",
          "ส่งช่างภายนอก",
          "ส่งช่างภายนอก (KDDS)",
          "สั่งอะไหล่",
          "KDDS ดำเนินการ",
          "รอตรวจรับงาน",
          "เสร็จสิ้น",
          "งานตีกลับ",
          "ยกเลิกงาน",
        ],
      },
      {
        accessorFn: (row) => new Date(row.createdAt),
        id: "createdAt",
        header: "วันที่แจ้งซ่อม",
        Cell: ({ cell }) => new Date(cell.getValue()).toLocaleString(),
        enableColumnFilter:false,
        // filterVariant: "date",
        // enableEditing: false,
        // muiFilterDatePickerProps: {
        //   disableFuture: true,
        // },
      },
    ],
    []
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    initialState
  ]);

  const table = useMaterialReactTable({
    columns,
    data,
    // layoutMode: "grid-no-grow",
    positionPagination: "both",
    getRowId: (row) => row.id,
    createDisplayMode: "modal", //default ('row', and 'custom' are also available)
    editDisplayMode: "modal", //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    enableGlobalFilter: false,
    initialState: { showColumnFilters: true },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    muiBottomToolbarProps: {
      sx: {
        minHeight: "5rem",
      },
    },
    muiTableBodyProps: {
      sx: {
        //stripe the rows, make odd rows a darker color
        "& td:nth-of-type(odd)": {
          backgroundColor: "#f5f5f5",
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderRight: "2px solid #e0e0e0", //add a border between columns
      },
    },
    enableFullScreenToggle: false,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="ดูเพิ่มเติม">
          <Link to={`/job/${row.original.id}`} state={{backUrl:thisLink}}>
            <IconButton
            // onClick={() => navigate(`/job/${row.original.id}`)}
            >
              <Preview />
            </IconButton>
          </Link>
        </Tooltip>
      </Box>
    ),
    positionActionsColumn: "first",
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    autoResetPageIndex: false,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      {initialState && <MaterialReactTable table={table} />}
    </LocalizationProvider>
  );
};

export default MyAllEstimateDataTable;
