import Compressor from "compressorjs";

export const compressImage = async (
    file,
) => {
    return await new Promise((resolve, reject) => {
        new Compressor(file, {
            quality: 0.7,
            maxWidth: 1920,
            maxHeight: 1080,
            success: resolve,
            error: reject,
        });
    });
};