import React from "react";
import AllJobDataTable from "./AllJobDataTable";
import { Container } from "@mui/material";
import TopMenu from "../TopMenu/TopMenu";

export default function AllJob() {
  return (
    <div>
      <TopMenu back="/home" name="งานทั้งหมด" />
      <br />
      <Container maxWidth="xl">
        <AllJobDataTable />
      </Container>
    </div>
  );
}
