import React, { useEffect, useState } from "react";
import { httpClient } from "../utils/HttpClient";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Container, Paper } from '@mui/material'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "งานแจ้งซ่อมที่เกินกำหนด - หมวดงาน",
    },
    datalabels: {
      color: '#000000',
      backgroundColor: '#F3EFF5',
      borderRadius: 4,
    }
  },
};

export default function OverdueTypeBarChart({dateRange}) {
  const [startDate, endDate] = dateRange;
  const [data, setData] = useState();
  const getData = async () => {
    const result = await httpClient.get("/jobs/graph-overdue-type",{
      params:{
        startDate,
        endDate
      }
    });
    const jobs = result.data;
    const labels = [];
    const datasets = [];
    for (let index = 0; index < jobs.length; index++) {
      const job = jobs[index];
      labels.push(job.name);
      datasets.push(job.jobCount);
    }
    setData({
      labels,
      datasets: [
        {
          label: "งานที่เกินกำหนด",
          data: datasets,
          backgroundColor: "rgba(196, 22, 22, 1)",
        },
      ],
    });
  };
  useEffect(() => {
    if (startDate == null && endDate == null) {
      getData();
    } else if (startDate && endDate) {
      getData();
    }
  }, [startDate, endDate]);
  return (
    <div>
      <br />
      <Container maxWidth='xl' >
        <Paper elevation={3} >
          {data && <Bar options={options} data={data} plugins={[ChartDataLabels]} />}
        </Paper>
      </Container>
    </div>
  );
}
