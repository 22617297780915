
export const ROLE_NAME = {
    roleAdmin:"ผู้ดูแลระบบ",
    roleAssessor:"ผู้ประเมิน",
    roleRepairMan:"ช่างซ่อม",
}

// for dev
export const LIFF_ID = process.env.REACT_APP_LIFF_ID
export const API_URL = process.env.REACT_APP_API_URL

// for test
// export const LIFF_ID = '1657811607-KQl1O8p8'
// export const API_URL = 'https://fixit-test-api.sapanfun.com'

// for prod
// export const LIFF_ID = '1657811607-gemG6EJE'
// export const API_URL = 'https://fixit-api.toyotakhonkaen.com'
