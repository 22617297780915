import {
  Avatar,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useLiffContext } from "../../LiffContext";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { httpClient } from "../utils/HttpClient";
import Swal from "sweetalert2";
import { API_URL, ROLE_NAME } from "../../config";
import { format } from "date-fns";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import FormData from "form-data";
import { compressImage } from '../utils/compressImage'

import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";

const defaultData = {
  nextStep: "",
  detail: "",
  staff: "",
};

export default function NewJobAction({ Init, successCallBackUrl }) {
  const { liffFn } = useLiffContext();
  const [images, setImages] = useState([]);
  const navigate = useNavigate();

  const [data, setData] = useState(defaultData);
  const [open, setOpen] = useState(false);

  const changeData = (e) => {
    setData((oldData) => {
      let newData = { ...oldData };
      newData[e.target.name] = e.target.value;
      return newData;
    });
  };

  const handleUpdate = async () => {
    setOpen(true);
    let data2 = new FormData();
    data2.append("data", JSON.stringify(data));
    data2.append("countFile", files.length);
    let indexKey = 0
    for (const element of files) {
      const tinyImage = await compressImage(element.file)
      data2.append(`file${indexKey}`, tinyImage);
      indexKey += 1
    }


    const result = await httpClient.post(`/jobs/update-newjob/${Init.job.id}`, data2, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    setOpen(false);
    if (result.data.status) {
      Swal.fire({
        title: "สำเร็จ!",
        text: `อัพเดทเรียบร้อยแล้ว`,
        icon: "success",
        confirmButtonText: "ตกลง",
      }).then(() => {
        navigate(successCallBackUrl);
      });
    } else {
      Swal.fire({
        title: "ขออภัย!",
        text: `กรุณาลองใหม่อีกครัั้งในภายหลัง หรือติดต่อเจ้าหน้าที่`,
        icon: "error",
        confirmButtonText: "ตกลง",
      });
    }
  };

  const [files, setFiles] = useState([]);
  const [imageSrc, setImageSrc] = useState(undefined);
  const updateFiles = (incommingFiles) => {
    setFiles(incommingFiles);
  };
  const onDelete = (id) => {
    setFiles(files.filter((x) => x.id !== id));
  };
  const handleSee = (imageSource) => {
    setImageSrc(imageSource);
  };

  const checkDisabled = () => {
    if (data.nextStep != "") {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Divider textAlign="left" sx={{ my: 1 }}>
        <Chip
          sx={{ lineHeight: 1.8 }}
          label={<strong>สำหรับผู้ประเมิน</strong>}
        />
      </Divider>
      <TextField
        variant="outlined"
        fullWidth
        label="Next Step *"
        value={data.nextStep ? data.nextStep : ""}
        name="nextStep"
        select
        size="small"
        type={"select"}
        // defaultValue= {null}
        sx={{ mt: 2 }}
        onChange={(e) => {
          changeData(e);
        }}
      >
        <MenuItem value={"รอตรวจรับงาน"}>ซ่อมได้</MenuItem>
        {/* <MenuItem value={"ซ่อมไม่ได้"}>ซ่อมไม่ได้</MenuItem> */}
        <MenuItem value={"สั่งอะไหล่"}>สั่งอะไหล่</MenuItem>
        <MenuItem value={"ส่งช่างภายใน"}>ส่งช่างภายใน</MenuItem>
        <MenuItem value={"ส่งช่างภายนอก"}>ส่งช่างภายนอก</MenuItem>
      </TextField>
      {data && data.nextStep === "ส่งช่างภายใน" && (
        <TextField
          variant="outlined"
          fullWidth
          label="ช่างซ่อม *"
          value={data.staff ? data.staff : ""}
          name="staff"
          select
          size="small"
          type={"select"}
          sx={{ mt: 2 }}
          onChange={(e) => {
            changeData(e);
          }}
        >
          {Init.staff.map((st, key) => (
            <MenuItem
              key={key}
              value={st.employeeId}
            >{`${st.firstName} ${st.lastName}`}</MenuItem>
          ))}
        </TextField>
      )}
      <TextField
        variant="outlined"
        fullWidth
        label="รายละเอียด"
        size="small"
        value={data.detail}
        name="detail"
        multiline
        rows={4}
        sx={{ mt: 2 }}
        onChange={(e) => {
          changeData(e);
        }}
      />
      <Dropzone
        onChange={updateFiles}
        value={files}
        // onClean
        accept={"image/*"}
        label={"กดที่นี่เพื่อเลือกรูปประกอบ"}
        minHeight={"195px"}
        maxHeight={"500px"}
        disableScroll
        footer={false}
        maxFiles={5}
        style={{ marginTop: "15px" }}
      >
        {files.length > 0 &&
          files.map((file) => (
            <FileItem
              {...file}
              key={file.id}
              onDelete={onDelete}
              alwaysActive
              onSee={handleSee}
              hd
              preview
              //   info
              resultOnTooltip
            />
          ))}
      </Dropzone>
      {imageSrc && (
        <FullScreenPreview
          imgSource={imageSrc}
          openImage={imageSrc}
          onClose={(e) => handleSee(undefined)}
        />
      )}

      <Button
        disabled={checkDisabled()}
        onClick={() => handleUpdate()}
        variant="contained"
        sx={{ my: 2 }}
        fullWidth
        size="small"
      >
        บันทึก
      </Button>
    </div>
  );
}
