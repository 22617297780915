import * as React from 'react'

const LiffContext = React.createContext()

export function useLiffContext(){
    return React.useContext(LiffContext)
}

const initialLiff = {
   isLogin:false,
   liff:{}
}

export default function LiffProvider({children}) {
    const [liffFn , setLiffFn] = React.useState(initialLiff)
    const [pending , setPending] = React.useState(true)

    const setLiff = async(liff) => { 
        await setLiffFn({
         isLogin:liff.isLogin,
         liff:liff.liff
        })
        await setPending(false)
     }

     const liffStore = {
        liffFn,
        setLiff
     }

     return (
        <LiffContext.Provider value={liffStore} >{children}</LiffContext.Provider>
     )
    
}

