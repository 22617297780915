import { Routes, Route } from "react-router-dom";
import Login from "./Component/Login/Login";
import Regis from "./Component/Regis/Regis";
import PrivateRoute from "./PrivateRoute";
import Home from "./Component/Home/Home";
import NewJob from "./Component/NewJob/NewJob";
import MyJob from "./Component/MyJob/MyJob";
import MyEstimate from "./Component/MyEstimate/MyEstimate";
import Job from "./Component/Job/Job";
import Users from "./Component/Users/Users";
import ChangeJobType from "./Component/ChangeJobType/ChangeJobType";
import MyRepair from "./Component/MyRepair/MyRepair";
import EditUser from "./Component/EditUser/EditUser";
import ExportExcel from "./Component/ExportExcel/ExportExcel";
import MyAllEstimate from "./Component/MyAllEstimate/MyAllEstimate";
import NewMyAllEstimate from "./Component/NewMyAllEstimate";
import Dashboard from "./Component/Dashboard";
import AllJob from "./Component/AllJob";
import NewTeam from "./Component/Team/NewTeam";
import TeamManagement from "./Component/Team";
import TeamMember from "./Component/TeamMember";
import TeamIdManagement from "./Component/Team/TeamId";
import AllJobInTeam from "./Component/AllJobInTeam";


const Router = () => {
  return (
    <Routes>
        <Route path="/" element={<PrivateRoute />}>
          <Route exact path="/regis" element={<Regis />} />
          <Route exact path="/home" element={<Home />} />
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/new-job" element={<NewJob />} />
          <Route exact path="/my-job" element={<MyJob />} />
          <Route exact path="/job/:id" element={<Job />} />
          <Route exact path="/edit-user/:id" element={<EditUser />} />
          <Route exact path="/my-estimate" element={<MyEstimate />} />
          <Route exact path="/my-all-estimate" element={<MyAllEstimate />} />
          <Route exact path="/all-job" element={<AllJob />} />
          <Route exact path="/all-job-in-team" element={<AllJobInTeam />} />
          <Route exact path="/new-my-all-estimate" element={<NewMyAllEstimate />} />
          <Route exact path="/my-repair" element={<MyRepair />} />
          <Route exact path="/change-job-type" element={<ChangeJobType />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
          <Route exact path="/export" element={<ExportExcel />} />

          <Route exact path="/new-team" element={<NewTeam />} />
          <Route exact path="/teams" element={<TeamManagement />} />
          <Route exact path="/team-member/:role/:id" element={<TeamMember />} />
          <Route exact path="/team/:id" element={<TeamIdManagement />} />
        </Route>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Login />} />
        
        <Route path="*" element={<ErrorBoundary />} />
    </Routes>
  );
};

function ErrorBoundary() {
  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <h1>404 - Page Not Found</h1>
    </div>
  );
}

export default Router;
