import React from "react";
// import { useLiff } from "react-liff";
import { Navigate, Outlet } from "react-router-dom";
import { useLiffContext } from "./LiffContext";
import { useSearchParams } from "react-router-dom";

function PrivateRoute() {
  const { liffFn } = useLiffContext()
  let [searchParams, setSearchParams] = useSearchParams();
  const thisPath = window.location.pathname
  return liffFn.isLogin ? <Outlet /> : <Navigate to={`/login?oldUrl=${thisPath}?${encodeURIComponent(searchParams.toString())}`} />;
}

export default PrivateRoute;
