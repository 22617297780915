const LineFlexMessage = (
  jobStatus,
  jobType,
  jobDetail,
  jobAddress,
  jobStartDate,
  jobOwner,
  jobLog,
  jobId,
  jobUrl
) => {
  return {
    type: "flex",
    altText: "แจ้งเตือนจาก TKKGroup ซ่อมได้",
    contents: {
      type: "bubble",
      body: {
        type: "box",
        layout: "vertical",
        contents: [
          {
            type: "text",
            text: jobStatus,
            weight: "bold",
            color: "#1DB446",
            size: "sm",
          },
          {
            type: "text",
            text: jobType,
            weight: "bold",
            size: "lg",
            margin: "md",
            wrap: true,
            maxLines: 2,
          },
          {
            type: "text",
            text: jobDetail,
            size: "xs",
            wrap: true,
            maxLines: 2,
          },
          {
            type: "separator",
            margin: "xxl",
          },
          {
            type: "box",
            layout: "vertical",
            margin: "xxl",
            spacing: "sm",
            contents: [
              {
                type: "box",
                layout: "horizontal",
                contents: [
                  {
                    type: "text",
                    text: "สถานที่",
                    size: "sm",
                    flex: 0,
                    weight: "bold",
                  },
                  {
                    type: "text",
                    text: jobAddress,
                    size: "sm",
                    color: "#555555",
                    align: "end",
                    wrap: true,
                    weight: "regular",
                    maxLines: 2,
                  },
                ],
              },
              {
                type: "box",
                layout: "horizontal",
                contents: [
                  {
                    type: "text",
                    text: "วันที่แจ้ง",
                    size: "sm",
                    flex: 0,
                    weight: "bold",
                  },
                  {
                    type: "text",
                    text: jobStartDate,
                    size: "sm",
                    color: "#555555",
                    align: "end",
                    wrap: true,
                    weight: "regular",
                    maxLines: 2,
                  },
                ],
              },
              {
                type: "box",
                layout: "horizontal",
                contents: [
                  {
                    type: "text",
                    text: "ผู้แจ้ง",
                    size: "sm",
                    flex: 0,
                    weight: "bold",
                  },
                  {
                    type: "text",
                    text: jobOwner,
                    size: "sm",
                    color: "#555555",
                    align: "end",
                    wrap: true,
                    weight: "regular",
                    maxLines: 2,
                  },
                ],
              },
            ],
          },
          {
            type: "separator",
            margin: "xxl",
          },
          {
            type: "box",
            layout: "horizontal",
            margin: "md",
            contents: [
              {
                type: "text",
                text: "JOB ID",
                size: "xs",
                color: "#aaaaaa",
                flex: 0,
              },
              {
                type: "text",
                text: `#${jobId}`,
                color: "#aaaaaa",
                size: "xs",
                align: "end",
              },
            ],
          },
        ],
      },
      footer: {
        type: "box",
        layout: "vertical",
        contents: [
          {
            type: "button",
            action: {
              type: "uri",
              label: "ดูข้อมูลเพิ่มเติม",
              uri: jobUrl,
            },
            style: "primary",
          },
        ],
      },
      styles: {
        footer: {
          separator: true,
        },
      },
    },
  };
};

export default LineFlexMessage;
