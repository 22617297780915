import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { useNavigate } from "react-router-dom";

export default function ActionAreaCard({ title, body, link }) {
  const navigate = useNavigate();
  return (
    <Card sx={{ backgroundColor: "#fff", borderRadius: 5, mt:1 }}>
      <CardActionArea onClick={() => navigate(link)}>
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {body}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
