import React from "react";
import AllJobInTeamDataTable from "./AllJobInTeamDataTable";
import { Container } from "@mui/material";
import TopMenu from "../TopMenu/TopMenu";

export default function AllJobInTeam() {
  return (
    <div>
      <TopMenu back="/home" name="งานทั้งหมดในทีม" />
      <br />
      <Container maxWidth="xl">
        <AllJobInTeamDataTable />
      </Container>
    </div>
  );
}
