import {
  Box,
  Container,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  MenuList,
  MenuItem,
  ListItemText,
  Divider,
  Paper,
  ListItemIcon,
  Grid,
  TextField,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import { httpClient } from "../utils/HttpClient";
import { useLiffContext } from "../../LiffContext";
import { useNavigate } from "react-router-dom";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import JobCard from "./JobCard";
import TopMenu from "../TopMenu/TopMenu";
import { CheckBox } from "@mui/icons-material";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const allStatus = [
  "งานใหม่",
  "งานใหม่(เปลี่ยนหมวด)",
  "เปลี่ยนหมวดงาน",
  "งานตีกลับ",
  "ส่งช่างภายนอก",
  "ผู้ประเมินตรวจรับงาน",
  "ส่งช่างภายใน",
  "สั่งอะไหล่",
  "รอตรวจรับงาน",
  "เสร็จสิ้น",
]

export default function MyJob() {
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(true);
  const [queryData, setQueryData] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      // const profileLine = await liffFn.liff.getAccessToken()
      // await setProfile(profileLine)
      const result = await httpClient.get(`jobs/my-job`);
      if (result.data.status) {
        const resultData = result.data;
        setData(result.data);
        setPage(1);
        // setNoti(result.data.noti);
        setOpen(false);
      }
    })();
  }, []);

  const loadMore = async () => {
    setOpen(true);

    const result = await httpClient.get(`jobs/my-job?page=${page + 1}`);
    if (result.data.status) {
      setData((oldData) => {
        const { allJob, ...newData } = result.data;
        return {
          allJob: [...oldData.allJob, ...allJob],
          ...newData,
        };
      });
      // setNoti(result.data.noti);
      await setPage((oldPage) => oldPage + 1);
      setOpen(false);
    }
  };

  return (
    <>
      <TopMenu back="/home" name="รายละเอียดงานซ่อม" />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {/* <Paper>
        <Grid container sx={{ m: 1 }} alignItems="center" spacing={1}>
          <Grid item xs={8}>
            <Autocomplete
              disablePortal
              multiple
              disableCloseOnSelect
              sx={{ mt: 2 }}
              noOptionsText="ไม่มีสถานะที่ค้นหา"
              // value={data.branch}
              isOptionEqualToValue={(option, value) => {
                return option == value;
              }}
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <CheckBox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              // onChange={(e, newValue) => {
              //   setData((oldData) => {
              //     let newData = { ...oldData };
              //     newData["branch"] = newValue;
              //     return newData;
              //   });
              // }}
              options={allStatus}
              name="status"
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  label="สถานะ"
                />
              )}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              variant="outlined"
              size="meduim"
              fullWidth
              // onClick={() => handleSearch()}
            >
              ค้นหา
            </Button>
          </Grid>
        </Grid>
      </Paper> */}
      <br />
      {data
        ? data.allJob.map((job, key) => (
            <div
              key={key}
              onClick={() => {
                navigate(`/job/${job.id}`);
              }}
            >
              <JobCard job={job} />
            </div>
          ))
        : null}
      <br />
      {data && (
        <Typography variant="body1" sx={{ my: 1 }} align="center">
          {`จำนวน ${data.allJob.length} จากทั้งหมด ${data.total}`}
        </Typography>
      )}
      {data && data.allJob.length < data.total ? (
        <div style={{ textAlign: "center" }}>
          <Button
            sx={{ mb: 1 }}
            variant="contained"
            size="small"
            onClick={() => loadMore()}
          >
            โหลดเพิ่ม
          </Button>
        </div>
      ) : null}
    </>
  );
}
