import React from "react";
import Typography from "@mui/material/Typography";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Pagination,
  TextField,
  Toolbar,
} from "@mui/material";
import axios from "axios";

import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import LoadingBackdrop from "../../Loading";
import { blue } from "@mui/material/colors";
import { ArrowBackIos, Edit, SwapHoriz } from "@mui/icons-material";
import Swal from "sweetalert2";
import { useNavigate, useParams } from 'react-router-dom'
import { httpClient } from "../utils/HttpClient";
import TopMenu from "../TopMenu/TopMenu";


const TeamIdManagement = () => {
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [role, setRole] = useState("all");


  const navigate = useNavigate();
  const { id } = useParams()
  const [isLoading, setIsLoading] = useState(false);

  const getData = async (pageNew, roleNew) => {
    setIsLoading(true)
    const result = await httpClient.get(`admin/team/${id}`, {
      params: {
        page: pageNew,
        limit: 10,
        search,
        role: roleNew ? roleNew : role
      },
    });
    setData(result.data);
    setIsLoading(false)
  };

  useEffect(() => {
    getData(1)
  }, []);

  if (!data) {
    return <LoadingBackdrop />;
  }

  const searchText = async (text) => {
    setSearch(text);
  };

  const onSearch = async () => {
    setPage(1);
    await getData(1);
  };

  const onChangePage = async (pageNew) => {
    setPage(pageNew);
    await getData(pageNew);
  };

  const handleRole = async (role) => {
    setRole(role)
    await getData(1, role)
  }

  const handleDelete = async (teamMemmberId) => {
    Swal.fire({
      icon: "warning",
      showCancelButton: true,
      title: "ยืนยันที่จะลบไหม",
      confirmButtonText: "ยืนยัน",
      cancelButtonText: "ยกเลิก",
      confirmButtonColor: "#F21826",
      cancelButtonColor: "#000000",
    }).then(async (value) => {
      if (value.isConfirmed) {
        setIsLoading(true)
        const result = await httpClient.delete(`/admin/team-member/${teamMemmberId}`)
        if (data) {
          const newData = data.user.filter((user) => user.id !== teamMemmberId)
          setData({
            ...data,
            user: newData
          })
        }
        setIsLoading(false)
      }
    })
  }

  return (
    <div>
      <TopMenu back="/teams" name="จัดการทีม" />
      
      {isLoading && <LoadingBackdrop />}
      <Container>
        <Box display="flex">
          <Box m="auto">
            <Paper elevation={5} sx={{ mt: 10, mb: 3, width: 345 }}>
              <div style={{ padding: 5 }}>
                <Paper elevation={3} >
                  <div style={{ padding: 3 }}>
                    <Typography variant="h5" align="center" sx={{ my: 3 }}>
                      สมาชิกในทีม {data?.team?.name}
                    </Typography>
                    <Typography align="center" variant="body2" >ตัวกรอง</Typography>
                    <TextField
                      variant="outlined"
                      label="ค้นหาด้วยชื่อ หรือ รหัสพนักงาน"
                      fullWidth
                      sx={{ my: 1 }}
                      onChange={(e) => searchText(e.target.value)}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                          onSearch();
                        }
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position="end"><IconButton
                          sx={{ p: "10px" }}
                          aria-label="search"
                          onClick={onSearch}
                        >
                          <SearchIcon />
                        </IconButton></InputAdornment>,
                      }}
                    >
                    </TextField>

                    <TextField
                      select
                      variant="outlined"
                      label="ประเภทสมาชิก"
                      fullWidth
                      sx={{ mt: 2 }}
                      value={role}
                      onChange={(e) => {
                        handleRole(e.target.value)
                      }}
                    >
                      <MenuItem value={"all"}>
                        ทั้งหมด
                      </MenuItem>
                      <MenuItem value={"head"}>
                        หัวหน้า
                      </MenuItem>
                      <MenuItem value={"member"}>
                        สมาชิก
                      </MenuItem>
                    </TextField>
                  </div>
                </Paper>

                <MenuList sx={{ my: 1 }}>
                  {data ? (
                    data.user.length > 0 ? (
                      data.user.map((user, key) => (
                        <div key={key}>
                          <MenuItem>
                            <ListItemText>
                              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <div>
                                  <p style={user.role === "head" ? {color:"red"}:{}}>{user.User.fullName} ({user.User.employeeId})</p>
                                </div>
                                <Button size="small" variant="outlined" onClick={() => handleDelete(user.id)}>ลบ</Button>
                              </div>
                            </ListItemText>
                          </MenuItem>
                          <Divider />
                        </div>
                      ))
                    ) : (
                      <Typography align="center">
                        ไม่พบข้อมูลที่ท่านค้นหา
                      </Typography>
                    )
                  ) : (
                    <Typography align="center">กำลังโหลด</Typography>
                  )}
                </MenuList>
                {data?.count > 0 &&  (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Pagination
                      count={Math.ceil(data.count / data.limit)}
                      page={page}
                      onChange={(e, newPage) => {
                        onChangePage(newPage);
                      }}
                      color="primary"
                      size="small"
                    />
                  </Box>
                )}
                <Typography className="my-2" variant="body2" align="center">
                  {data && `พบทั้งหมด ${data.count} รายชื่อ`}
                </Typography>
                <p style={{color:"red",textAlign:"center"}}>* ตัวหนังสือสีแดงคือหัวหน้าทีม</p>
              </div>
            </Paper>
          </Box>
        </Box>
      </Container>
      {/* <SimpleDialog
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
        liff={liff}
      /> */}
    </div>
  );
};

export default TeamIdManagement;
