import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  Chip,
  Container,
  TextField,
  Typography,
  CardContent,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useLiffContext } from "../../LiffContext";
import { httpClient } from "../utils/HttpClient";
import "./Swal.css";
import { registerLocale } from "react-datepicker";
import th from "date-fns/locale/th";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import Swal from "sweetalert2";
import format from "date-fns/format";
import sub from "date-fns/sub";
import { useNavigate } from "react-router-dom";

registerLocale("th", th);

export default function Regis() {
  const { liffFn } = useLiffContext();
  const [profile, setProfile] = useState();
  const [loading, setLoading] = useState(true);
  const [employeeId, setEmployeeId] = useState("");
  const [dob, setDob] = useState("");
  const navigate = useNavigate()

  useEffect(() => {
    (async () => {
      const profileLine = await liffFn.liff.getProfile();
      await setProfile(profileLine);
      const result = await httpClient.get("/user/check-regis");
      if (result.data.status === true) {
        if (result.data.user.dbUser) {
          Swal.fire({
            title: "คุณลงทะเบียนแล้ว!",
            text: "คุณทำการเชื่อมรหัสพนักงานกับบัญชีไลน์แล้ว",
            icon: "warning",
            confirmButtonText: "ตกลง",
          }).then(() => {
            liffFn.liff.closeWindow();
            navigate("/home")
          });
        } else {
          setLoading(false);
        }
      }
    })();
  }, []);

  const handleSubmit = async () => {
    setLoading(true);
    const result = await httpClient.post("/user/regis", {
      employeeId,
      dateOfBirth: format(dob, "dd/MM/yyyy"),
    });
    setLoading(false);
    if (result.data.status) {
      Swal.fire({
        title: "สำเร็จ!",
        text: `ทำการเชื่อมข้อมูลกับ ${result.data.updateUser.firstName} ${result.data.updateUser.lastName} เรียบร้อยแล้ว`,
        icon: "success",
        confirmButtonText: "ตกลง",
      }).then(() => {
        liffFn.liff
          .sendMessages([
            {
              type: "text",
              text: `ทำการเชื่อมข้อมูลกับ ${result.data.updateUser.firstName} ${result.data.updateUser.lastName} เรียบร้อยแล้ว`,
            },
          ])
          .then(() => {
            liffFn.liff.closeWindow();
            navigate("/home")
          })
          .catch((err) => {
            console.log("error", err);
          });
      });
    } else {
      Swal.fire({
        title: "ขออภัย!",
        text: `${result.data.msg} กรุณาลองใหม่อีกครัั้งในภายหลัง หรือติดต่อเจ้าหน้าที่`,
        icon: "error",
        confirmButtonText: "ตกลง",
      });
    }
  };
  return (
    <>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Card sx={{ width: 345 }}>
            <CardContent sx={{ textAlign: "center" }}>
              <Typography component="h1" variant="h5">
                ยินดีต้อนรับ
              </Typography>
              <Chip
                variant="filled"
                sx={{ mt: 1 }}
                avatar={
                  profile ? (
                    <Avatar src={profile.pictureUrl} />
                  ) : (
                    <Skeleton animation="wave" variant="circular" />
                  )
                }
                label={
                  profile ? (
                    profile.displayName
                  ) : (
                    <Skeleton
                      sx={{ width: 40 }}
                      animation="wave"
                      variant="rectangular"
                    />
                  )
                }
              />
              {!loading ? (
                <div>
                  <Typography variant="subtitle1" align="center" sx={{ mt: 1 }}>
                    กรุณาระบุทำการลงทะเบียน เพื่อเริ่มต้นใช้งาน
                  </Typography>

                  <TextField
                    fullWidth
                    sx={{ mt: 2 }}
                    variant="outlined"
                    type="number"
                    size="large"
                    label="รหัสพนักงาน *"
                    value={employeeId}
                    onChange={(e) => {
                      e.preventDefault();
                      setEmployeeId(e.target.value);
                    }}
                  />

                  <DatePicker
                    selected={dob}
                    onChange={(date) => setDob(date)}
                    locale="th"
                    dateFormat="dd/MM/yyyy"
                    showMonthDropdown
                    showYearDropdown
                    maxDate={sub(new Date(), { years: 15 })}
                    dropdownMode="select"
                    customInput={
                      <TextField
                        fullWidth
                        sx={{ mt: 2 }}
                        variant="outlined"
                        size="large"
                        label="วัน เดือน ปี ค.ศ. ที่เกิด *"
                      />
                    }
                  />

                  <Button
                    fullWidth
                    variant="contained"
                    size="large"
                    sx={{ mt: 2 }}
                    disabled={dob === "" || employeeId === ""}
                    onClick={() => handleSubmit()}
                  >
                    บันทึกข้อมูล
                  </Button>
                </div>
              ) : (
                <Skeleton
                  sx={{ height: 230, mt: 1 }}
                  animation="wave"
                  variant="rectangular"
                />
              )}
              {/* <Copyright sx={{ mt: 3, mb: 1 }} /> */}
            </CardContent>
          </Card>
        </Box>
      </Container>
    </>
  );
}
