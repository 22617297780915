import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import Router from "./Router";
import { createTheme, ThemeProvider } from "@mui/material/styles";
// import { LiffProvider } from "react-liff";
import  LiffProvider  from "./LiffContext";
// import { LIFF_ID } from "./config";
// import Loading from './Loading'

// import 'sweetalert2/src/sweetalert2.scss'
import "./index.css"


// import VConsole from 'vconsole';


// const vConsole = new VConsole({ theme: 'dark' });

const theme = createTheme({
  typography: {
    fontFamily: "Sarabun, sans-serif",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'Sarabun, sans-serif';
          font-display: swap;
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
      `,
    },
  },
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LiffProvider>
    {/* <LiffProvider liffId={LIFF_ID} withLoginOnExternalBrowser={true} > */}
      <ThemeProvider theme={theme} >
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </ThemeProvider>
    </LiffProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
