import {
  Box,
  Card,
  Container,
  CardContent,
  TextField,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  MenuList,
  MenuItem,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  ListItemIcon,
  Switch,
  FormControl,
  Autocomplete,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import PersonIcon from "@mui/icons-material/Person";
import { httpClient } from "../utils/HttpClient";
import { registerLocale } from "react-datepicker";
import th from "date-fns/locale/th";
import sub from "date-fns/sub";

import DatePicker from "react-datepicker";
import { format } from "date-fns";
import TopMenu from "../TopMenu/TopMenu";

registerLocale("th", th);

export default function EditUser() {
  const defaultData = {
    branch: [],
    jobGroup: [],
    role: [],
  };
  const [data, setData] = useState(defaultData);
  const [init, setInit] = useState();
  const [open, setOpen] = useState(true);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      // const profileLine = await liffFn.liff.getAccessToken()
      // await setProfile(profileLine)
      const result = await httpClient.get(`user/get-user-by-id/${id}`);
      const result2 = await httpClient.get(`jobs/init`);
      if (result.data.status && result2.data.status) {
        const resultData = result.data.user;

        setData((oldData) => {
          const userBranch = resultData.UserBranches.map((ub) => {
            return { id: ub.id, name: ub.Branch.name };
          });
          const userJobGroup = resultData.UserJobGroups.map((ujg) => {
            return { id: ujg.id, name: ujg.JobGroup.name };
          });
          let userRole = [];
          if (resultData.roleAdmin) {
            userRole.push({ id: "roleAdmin", name: "แอดมิน" });
          }
          if (resultData.roleAssessor) {
            userRole.push({ id: "roleAssessor", name: "ผู้ประเมิน" });
          }
          if (resultData.roleRepairMan) {
            userRole.push({ id: "roleRepairMan", name: "ช่างซ่อม" });
          }
          return {
            ...oldData,
            branch: userBranch,
            jobGroup: userJobGroup,
            role: userRole,
            name: resultData.fullName,
          };
        });
        setInit(result2.data);
        setOpen(false);
      }
    })();
  }, []);

  const handleSubmit = async () => {
    // console.log(data);
    setOpen(true);

    const roleToDb = {
      roleAdmin: false,
      roleAssessor: false,
      roleRepairMan: false,
    };

    data.role.forEach((element) => {
      if (element.id === "roleAdmin") {
        roleToDb.roleAdmin = true;
      } else if (element.id === "roleAssessor") {
        roleToDb.roleAssessor = true;
      } else if (element.id === "roleRepairMan") {
        roleToDb.roleRepairMan = true;
      }
    });

    const branchToDb = data.branch.map((element) => {
      return { employeeId: Number(id), branchId: element.id };
    });

    const JobGroupToDb = data.jobGroup.map((element) => {
      return { employeeId: Number(id), jobGroupId: element.id };
    });

    // console.log({ roleToDb, branchToDb, JobGroupToDb });

    const result = await httpClient.post(`/user/update-user-role/${id}`, {
      role:roleToDb,
      branch:branchToDb,
      jobGroup:JobGroupToDb
    });
    setOpen(false);
    if (result.data.status) {
      Swal.fire({
        title: "สำเร็จ!",
        text: `ทำการลงทะเบียน เรียบร้อยแล้ว`,
        icon: "success",
        confirmButtonText: "ตกลง",
      }).then(() => {
        navigate("/users");
      });
    } else {
      Swal.fire({
        title: "ขออภัย!",
        text: `กรุณาลองใหม่อีกครัั้งในภายหลัง หรือติดต่อเจ้าหน้าที่`,
        icon: "error",
        confirmButtonText: "ตกลง",
      });
    }
  };

  return (
    <>
      <TopMenu back="/users" name="แก้ไขสิทธิ์ User" />
      <br />
      {/* {console.log(Object.values(data).every((v) => {console.log(v , v==""); return v == ""}))} */}
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            // minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <Paper sx={{ width: 345 }}>
            {init && (
              <div style={{ margin: "10px" }}>
                <Typography align="center" sx={{ my: 2 }} variant="subtitle1">
                  ชื่อ: {data.name}
                </Typography>
                <Autocomplete
                  disablePortal
                  multiple
                  disableCloseOnSelect
                  noOptionsText="ไม่พบสิทธิ์ที่ค้นหา"
                  value={data.role}
                  isOptionEqualToValue={(option, value) => {
                    return option.id == value.id;
                  }}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, newValue) => {
                    setData((oldData) => {
                      let newData = { ...oldData };
                      newData["role"] = newValue;
                      return newData;
                    });
                  }}
                  options={[
                    { id: "roleRepairMan", name: "ช่างซ่อม" },
                    { id: "roleAssessor", name: "ผู้ประเมิน" },
                    { id: "roleAdmin", name: "แอดมิน" },
                  ]}
                  name="role"
                  renderInput={(params) => (
                    <TextField {...params} fullWidth label="สิทธิ์ *" />
                  )}
                />
                <Autocomplete
                  disablePortal
                  multiple
                  disableCloseOnSelect
                  sx={{ mt: 2 }}
                  noOptionsText="ไม่มีกลุ่มงานซ่อมที่ค้นหา"
                  value={data.jobGroup}
                  isOptionEqualToValue={(option, value) => {
                    return option.id == value.id;
                  }}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, newValue) => {
                    setData((oldData) => {
                      let newData = { ...oldData };
                      newData["jobGroup"] = newValue;
                      return newData;
                    });
                  }}
                  options={init.jobGroup}
                  name="jobGroup"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="กลุ่มงานซ่อมที่ดูแล *"
                    />
                  )}
                />
                <Autocomplete
                  disablePortal
                  multiple
                  disableCloseOnSelect
                  sx={{ mt: 2 }}
                  noOptionsText="ไม่มีสาขาที่ค้นหา"
                  value={data.branch}
                  isOptionEqualToValue={(option, value) => {
                    return option.id == value.id;
                  }}
                  getOptionLabel={(option) => option.name}
                  onChange={(e, newValue) => {
                    setData((oldData) => {
                      let newData = { ...oldData };
                      newData["branch"] = newValue;
                      return newData;
                    });
                  }}
                  options={init.branch}
                  name="branch"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      label="สาขาที่ดูแลงานแจ้งซ่อม *"
                    />
                  )}
                />
                <Button
                  variant="contained"
                  sx={{ mt: 2 }}
                  onClick={() => handleSubmit()}
                  fullWidth
                  // disabled={
                  //   data.branch === "" ||
                  //   data.employeeId === "" ||
                  //   data.name === "" ||
                  //   data.ddmsId === "" ||
                  //   data.role === "" ||
                  //   data.dateOfBirth === ""
                  // }
                >
                  บันทึกข้อมูล
                </Button>
                <Button
                  variant="outlined"
                  sx={{ mt: 1 }}
                  onClick={() => navigate("/users")}
                  fullWidth
                >
                  ย้อนกลับ
                </Button>
              </div>
            )}
          </Paper>
        </Box>
      </Container>
    </>
  );
}
