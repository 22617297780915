import {
  Box,
  Button,
  TextField,
} from "@mui/material";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom'
import { httpClient } from "../utils/HttpClient";


const schema = yup.object({
  empIds: yup.string().required(),
});

export default function EditTeamMemberForm({ id, role, setIsLoading }) {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      empIds: ""
    },
  });
  const navigate = useNavigate()


  const handleOnSubmit = async (evt) => {
    try {
      setIsLoading(true);
      const createCustomer = await httpClient.post(
        `admin/team-member/${id}`,
        {
          empIds:evt.empIds,
          role
        }
      );
      if (!createCustomer.data) {
        throw new Error("No data");
      }
      reset();
      setIsLoading(false);
      Swal.fire({
        icon: "success",
        title: "แก้ไขข้อมูลสำเร็จ",
        confirmButtonText: "ยืนยัน",
        confirmButtonColor: "#F21826",
        showCancelButton: false,
      }).then(async () => {
        navigate("/teams")
      });
    } catch (error) {
      setIsLoading(false);
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "มีบางอย่างผิดพลาด กรูณาลองใหม่ในภายหลัง!",
        confirmButtonText: "ยืนยัน",
        confirmButtonColor: "#F21826",
        showCancelButton: false,
      });
    }
  };

  return (
    <div>
      <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
        <Controller
          control={control}
          name="empIds"
          // defaultValue=""
          render={({ field }) => (
            <div className="my-3">
              <TextField
                {...field}
                fullWidth
                error={!!errors.empIds}
                helperText={errors?.empIds?.message?.toString()}
                variant="filled"
                label="รหัสพนักงานคั่นด้วย comma"
                multiline
                minRows={4}
                maxRows={18}
              />
            </div>
          )}
        />



        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          sx={{my:2}}
          className="my-6 rounded-full bg-tkk"
        >
          ยืนยัน
        </Button>

        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={() => navigate("/teams")}
          className="rounded-full"
        >
          ยกเลิก
        </Button>
      </Box>
    </div>
  );
}
