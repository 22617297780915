import {
  Avatar,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useLiffContext } from "../../LiffContext";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { httpClient } from "../utils/HttpClient";
import Swal from "sweetalert2";
import { API_URL, ROLE_NAME } from "../../config";
import { format } from "date-fns";
// import Lightbox from "react-spring-lightbox";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import TopMenu from "../TopMenu/TopMenu";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import SpairAndOutRepairAction from "./SpairAndOutRepairAction";
import NewJobAction from "./NewJobAction";
import ChangeJobTypeAction from "./ChangeJobTypeAction";
import RepairmanAction from "./RepairmanAction";
import JobLogCard from "./JobLogCard";
import AssessorApproveAction from "./AssessorApproveAction";
import OwnerApprove from "./OwnerApprove";
import html2canvas from "html2canvas";
import SendKDDSAction from "./SendKDDSAction";
import ProjectAction from "./ProjectAction";
// import { jsPDF } from "jspdf";

function SplitTime(numberOfHours) {
  var Days = Math.floor(numberOfHours / 24);
  var Remainder = numberOfHours % 24;
  var Hours = Math.floor(Remainder);
  var Minutes = Math.floor(60 * (Remainder - Hours));
  return `${Days > 0 ? ` ${Days} วัน` : ""}${Hours > 0 ? ` ${Hours} ชั่วโมง` : ""
    }${Minutes > 0 ? ` ${Minutes} นาที` : ""}`;
}

export default function Job() {
  const { liffFn } = useLiffContext();
  const [Init, setInit] = useState();
  const [images, setImages] = useState([]);
  const [images2, setImages2] = useState([]);
  const navigate = useNavigate();
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [open, setOpen] = useState(false);
  const { state } = useLocation();
  const backUrl = state?.backUrl;
  const successCallBackUrl = backUrl ? backUrl : "/home"

  const [currentImageIndex, setCurrentIndex] = useState(0);

  const gotoPrevious = () =>
    currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () =>
    currentImageIndex + 1 < images.length &&
    setCurrentIndex(currentImageIndex + 1);

  useEffect(() => {
    (async () => {
      setOpen(true);
      const result = await httpClient.get(`/jobs/job-id/${id}`);
      if (result.data.status === true) {
        await setInit(result.data);
        // await setUser(result.data.user);
        const imageList = await result.data.job.JobImages.map((image) => ({
          src: `${API_URL}/uploads/images/${image.fileName}`,
        }));
        await setImages(imageList);
      }
      setOpen(false);
    })();
  }, []);

  const renderAction = () => {
    const userBranch = Init.user.dbUser.UserBranches.map(
      (branch) => branch.branchId
    );
    const userJob = Init.user.dbUser.UserJobGroups.map(
      (jobGroup) => jobGroup.jobGroupId
    );

    if (Init.job.status === "เปลี่ยนหมวดงาน" && Init.user.dbUser.roleAdmin) {
      return (
        <Paper
          sx={{
            borderRadius: 10,
            py: 2,
            px: 2,
            mb: 2,
            backgroundColor: "#ffeeee",
          }}
          elevation={3}
        >
          <ChangeJobTypeAction Init={Init} successCallBackUrl={successCallBackUrl} />
        </Paper>
      );
    }

    if (
      Init.job.status === "รอตรวจรับงาน" &&
      (Init.user.dbUser.employeeId === Init.job.employeeId ||
        Init.user.dbUser.roleAdmin)
    ) {
      return (
        <Paper
          sx={{
            borderRadius: 10,
            py: 2,
            px: 2,
            mb: 2,
            backgroundColor: "#ffeeee",
          }}
          elevation={3}
        >
          <OwnerApprove Init={Init} successCallBackUrl={successCallBackUrl} />
        </Paper>
      );
    }

    if (Init.job.JobType.JobGroup.id === 1) {
      if (
        Init.job.status === "งานใหม่" ||
        Init.job.status === "งานใหม่(เปลี่ยนหมวด)" ||
        Init.job.status === "งานตีกลับ"
      ) {
        if (
          (Init.user.dbUser.roleAdmin || Init.user.dbUser.roleAssessor) &&
          userJob.includes(Init.job.JobType.jobGroupId)
        ) {
          return (
            <Paper
              sx={{
                borderRadius: 10,
                py: 2,
                px: 2,
                mb: 2,
                backgroundColor: "#ffeeee",
              }}
              elevation={3}
            >
              <SendKDDSAction Init={Init} successCallBackUrl={successCallBackUrl} />
            </Paper>
          );
        }
      } else {
        return null;
      }
    }

    if (
      Init.job.status === "งานใหม่" ||
      Init.job.status === "งานใหม่(เปลี่ยนหมวด)" ||
      Init.job.status === "งานตีกลับ"
    ) {
      if (
        Init.user.dbUser.roleAssessor &&
        userJob.includes(Init.job.JobType.jobGroupId) &&
        userBranch.includes(Init.job.branchId)
      ) {
        return (
          <Paper
            sx={{
              borderRadius: 10,
              py: 2,
              px: 2,
              mb: 2,
              backgroundColor: "#ffeeee",
            }}
            elevation={3}
          >
            <NewJobAction Init={Init} successCallBackUrl={successCallBackUrl} />
          </Paper>
        );
      }
    } else if (
      Init.job.status === "สั่งอะไหล่" ||
      Init.job.status === "ส่งช่างภายนอก"
    ) {
      if (
        Init.user.dbUser.roleAssessor &&
        userJob.includes(Init.job.JobType.jobGroupId) &&
        userBranch.includes(Init.job.branchId)
      ) {
        return (
          <Paper
            sx={{
              borderRadius: 10,
              py: 2,
              px: 2,
              mb: 2,
              backgroundColor: "#ffeeee",
            }}
            elevation={3}
          >
            <SpairAndOutRepairAction Init={Init} successCallBackUrl={successCallBackUrl} />
          </Paper>
        );
      }
    } else if (Init.job.status === "ส่งช่างภายใน") {
      if (
        Init.user.dbUser.roleRepairMan &&
        Init.user.dbUser.employeeId === Init.job.picId &&
        userJob.includes(Init.job.JobType.jobGroupId) &&
        userBranch.includes(Init.job.branchId)
      ) {
        return (
          <Paper
            sx={{
              borderRadius: 10,
              py: 2,
              px: 2,
              mb: 2,
              backgroundColor: "#ffeeee",
            }}
            elevation={3}
          >
            <RepairmanAction Init={Init} successCallBackUrl={successCallBackUrl} />
          </Paper>
        );
      }
    } else if (Init.job.status === "ผู้ประเมินตรวจรับงาน") {
      if (
        Init.user.dbUser.roleAssessor &&
        userJob.includes(Init.job.JobType.jobGroupId) &&
        userBranch.includes(Init.job.branchId)
      ) {
        return (
          <Paper
            sx={{
              borderRadius: 10,
              py: 2,
              px: 2,
              mb: 2,
              backgroundColor: "#ffeeee",
            }}
            elevation={3}
          >
            <AssessorApproveAction Init={Init} successCallBackUrl={successCallBackUrl} />
          </Paper>
        );
      }
    } else if (Init.job.status === "งานโครงการ") {
      if (
        (Init.user.dbUser.roleAssessor &&
          userJob.includes(Init.job.JobType.jobGroupId) &&
          userBranch.includes(Init.job.branchId)) || Init.user.dbUser.roleAdmin
      ) {
        return (
          <Paper
            sx={{
              borderRadius: 10,
              py: 2,
              px: 2,
              mb: 2,
              backgroundColor: "#ffeeee",
            }}
            elevation={3}
          >
            <ProjectAction Init={Init} successCallBackUrl={successCallBackUrl} />
          </Paper>
        );
      }
    } else {
      return null;
    }
  };

  const printRef = React.useRef();

  const handleDownloadPdf = async () => {
    setOpen(true);
    const element = printRef.current;
    const canvas = await html2canvas(element, {
      // allowTaint:true
      useCORS: true,
      backgroundColor: "#9ccfe0",
    });
    const data = canvas.toDataURL("png");
    setImages2([{ src: data }]);
    setIsOpen2(true);
    setOpen(false);
  };

  return (
    <>
      <TopMenu back={backUrl ? backUrl : "/home"} name="รายละเอียดงานซ่อม" />

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Lightbox
        open={isOpen2}
        close={() => setIsOpen2(false)}
        slides={images2}
        plugins={[Zoom]}
        animation={{ zoom: 500 }}
        carousel={{
          finite: true,
        }}
        controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
      />

      <Lightbox
        open={isOpen}
        close={() => setIsOpen(false)}
        slides={images}
        plugins={[Zoom]}
        animation={{ zoom: 500 }}
        carousel={{
          finite: true,
        }}
        controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
      />

      <br />
      <Container>
        <Button
          type="button"
          fullWidth
          variant="contained"
          onClick={handleDownloadPdf}
        >
          บันทึกภาพรายละเอียดงาน
        </Button>
      </Container>
      <Container ref={printRef}>
        <br />
        {Init && Init.job && (
          <div>
            {Init.job && Init.user && <div>{renderAction()}</div>}

            <Paper sx={{ borderRadius: 10, py: 2, px: 2 }} elevation={3}>
              <Divider textAlign="left" sx={{ my: 1 }}>
                <Chip
                  sx={{ lineHeight: 1.8 }}
                  label={<strong>ข้อมูลงานซ่อม</strong>}
                />
              </Divider>
              <Typography variant="subtitle2">
                <strong>เลขที่งาน:</strong> {Init.job.fullId}
              </Typography>
              <Typography variant="subtitle2">
                <strong>สถานะ:</strong> {Init.job.status}
              </Typography>
              <Typography variant="subtitle2">
                <strong>ประเภท:</strong> {Init.job.JobType.name}
              </Typography>
              <Typography variant="subtitle2">
                <strong>รายละเอียด:</strong> {Init.job.desc}
              </Typography>
              <Typography variant="subtitle2">
                <strong>สถานที่:</strong> สาขา {Init.job.Branch.name}{" "}
                {Init.job.address}
              </Typography>
              <Typography variant="subtitle2">
                <strong>วันที่แจ้ง:</strong>{" "}
                {format(new Date(Init.job.createdAt), "dd/MM/yyyy HH:mm")}
              </Typography>
              {Init.job.closedAt && (
                <Typography variant="subtitle2">
                  <strong>วันที่ซ่อมเสร็จ:</strong>{" "}
                  {format(new Date(Init.job.closedAt), "dd/MM/yyyy HH:mm")}
                </Typography>
              )}
              {Init.job.level && (
                <Typography variant="subtitle2">
                  <strong>ระดับงาน:</strong>
                  {` ${Init.job.level} (ประมาณเวลา ${SplitTime(
                    Init.job.JobType.JobGroup[`level${Init.job.level}Time`]
                  )})`}
                </Typography>
              )}

              <Divider textAlign="left" sx={{ my: 1 }}>
                <Chip
                  sx={{ lineHeight: 1.8 }}
                  label={<strong>ข้อมูลผู้แจ้งซ่อม</strong>}
                />
              </Divider>

              <Typography variant="subtitle1">
                <strong>
                  {Init.job.User.firstName} {Init.job.User.lastName}
                </strong>
              </Typography>
              <Typography variant="subtitle2">
                <strong>บริษัท:</strong> {Init.job.User.Company.name}
              </Typography>
              <Typography variant="subtitle2">
                <strong>สาขา:</strong> {Init.job.User.Branch.name}
              </Typography>
              <Typography variant="subtitle2">
                <strong>ฝ่าย:</strong> {Init.job.User.Division.name}
              </Typography>
              <Typography variant="subtitle2">
                <strong>แผนก:</strong> {Init.job.User.SubDivision.name}
              </Typography>
              <Typography variant="subtitle2">
                <strong>เบอร์มือถือ:</strong>{" "}
                <a href={`tel:/${Init.job.User.phoneNumber}`}>
                  {Init.job.User.phoneNumber}
                </a>
              </Typography>
              <Typography variant="subtitle2">
                <strong>เบอร์ภายใน:</strong> {Init.job.User.internalNumber}
              </Typography>

              {images && images.length > 0 && (
                <>
                  <Divider textAlign="left" sx={{ my: 1 }}>
                    <Chip label={<strong>รูปงานซ่อม</strong>} />
                  </Divider>
                  <Grid container spacing={1} justifyContent="center">
                    {images.map((image, key) => (
                      <Grid item xs={4} key={key}>
                        <div
                          onClick={() => {
                            setCurrentIndex(key);
                            setIsOpen(true);
                          }}
                        >
                          <Avatar
                            alt="รูปงานซ่อม"
                            variant="square"
                            sx={{ width: "100%", height: "100px" }}
                            src={image.src}
                          />
                        </div>
                      </Grid>
                    ))}
                  </Grid>
                </>
              )}
            </Paper>
            <br />
          </div>
        )}
        <br />
        {Init && Init.job && (
          <Paper
            sx={{
              borderRadius: 10,
              py: 2,
              px: 1,
              backgroundColor: "#ffffff80",
            }}
            elevation={3}
          >
            <Typography variant="h5" sx={{ my: 0.5 }} align="center">
              ประวัติการซ่อม
            </Typography>
            <VerticalTimeline animate={false}>
              {Init.job.JobLogs &&
                Init.job.JobLogs.map((jobLog, key) => {
                  const imageList = jobLog.JobLogImages.map((image) => {
                    let httpRegex =
                      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
                    if (httpRegex.test(image.fileName)) {
                      return {
                        src: `${image.fileName}`,
                      };
                    } else {
                      return {
                        src: `${API_URL}/uploads/images/${image.fileName}`,
                      };
                    }
                  });
                  return (
                    <VerticalTimelineElement
                      visible={true}
                      key={key}
                      className="vertical-timeline-element--work"
                      contentStyle={{
                        color: "#000",
                      }}
                      date={format(
                        new Date(jobLog.createdAt),
                        "dd/MM/yyyy เวลา HH:mm"
                      )}
                      iconStyle={{
                        background: "rgb(33, 150, 243)",
                        color: "#000",
                      }}
                    >
                      <h3 className="vertical-timeline-element-title">
                        {jobLog.status}
                      </h3>
                      <p>{jobLog.detail}</p>
                      <Divider sx={{ my: 1 }}></Divider>
                      {imageList.length > 0 && (
                        <JobLogCard images={imageList} />
                      )}
                    </VerticalTimelineElement>
                  );
                })}

              <VerticalTimelineElement
                className="vertical-timeline-element--work"
                visible={true}
                contentStyle={{
                  // background: "rgb(33, 150, 243)",
                  color: "#000",
                }}
                contentArrowStyle={
                  {
                    // borderRight: "7px solid  rgb(33, 150, 243)",
                  }
                }
                date={format(
                  new Date(Init.job.createdAt),
                  "dd/MM/yyyy เวลา HH:mm"
                )}
                iconStyle={{ background: "rgb(33, 150, 243)", color: "#000" }}
              // icon={<WorkIcon />}
              >
                <h3 className="vertical-timeline-element-title">
                  แจ้งซ่อมงานใหม่
                </h3>
                <p>
                  {Init.job.User.firstName} {Init.job.User.lastName}{" "}
                  แจ้งซ่อมงานใหม่
                </p>
              </VerticalTimelineElement>
            </VerticalTimeline>
          </Paper>
        )}
        <br />
        {/* <Button onClick={()=>window.print()} fullWidth variant="contained">พิมพ์หน้านี้</Button>
        <br />
        <br /> */}
      </Container>
    </>
  );
}
