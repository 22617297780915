import React, { useEffect, useState } from "react";
import { httpClient } from "../utils/HttpClient";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from "react-chartjs-2";
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Container, Paper } from '@mui/material'



const topLabel = {
  id: 'topLabel',
  afterDatasetsDraw(chart, args, pluginOptions) {
    const { ctx, scales: { x, y } } = chart;
    chart.data.datasets[0].data.forEach((datapoint, index) => {
      const datasetArray = [];
      chart.data.datasets.forEach((dataset) => {
        datasetArray.push(dataset.data[index])
      })
      // sum array
      function totalSum(total, values) {
        return total + values;
      };
      let sum = datasetArray.reduce(totalSum, 0);
      ctx.font = 'bold 18px sans-serif';
      ctx.fillStyle = 'rgba(255, 26, 104, 1)';
      ctx.textAlign = 'center';
      ctx.fillText(sum, x.getPixelForValue(index), chart.getDatasetMeta(2).data[index].y - 15);
    })
  }
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
);

export const options = {
  responsive: true,
  layout: {
    padding: 20
  },
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "งานแจ้งซ่อม",
    },
    datalabels: {
      color: '#000000',
      backgroundColor: '#F3EFF5',
      borderRadius: 4,
    },
    topLabel
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
      grace: 4
    },
  },
};

export default function StackGroupBar({ dateRange }) {
  const [startDate, endDate] = dateRange;
  const [data, setData] = useState();
  const getData = async () => {
    const result = await httpClient.get("/jobs/graph-stack",{
      params:{
        startDate,
        endDate
      }
    });
    const { labels, countSuccess, countOverDue, countOther } = result.data;
    setData({
      labels,
      datasets: [
        {
          label: 'งานค้าง',
          data: countOverDue,
          backgroundColor: '#FFA000',
        },
        {
          label: 'รอตรวจรับ',
          data: countOther,
          backgroundColor: '#5575FD',
        },
        {
          label: 'ซ่อมสำเร็จ',
          data: countSuccess,
          backgroundColor: '#2BAF2B',
        },
      ],
    });
  };
  useEffect(() => {
    if (startDate == null && endDate == null) {
      getData();
    } else if (startDate && endDate) {
      getData();
    }
  }, [startDate, endDate]);
  return (
    <div>
      <br />
      <Container maxWidth='xl' >
        <Paper elevation={3} >
          {data && <Bar options={options} data={data} plugins={[ChartDataLabels, topLabel]} />}
        </Paper>
      </Container>
    </div>
  );
}
