import React from "react";
import MyAllEstimateDataTable from "./MyAllEstimateDataTable";
import { Container } from "@mui/material";
import TopMenu from "../TopMenu/TopMenu";

export default function NewMyAllEstimate() {
  return (
    <div>
      <TopMenu back="/home" name="งานที่ต้องประเมิน" />
      <br />
      <Container maxWidth="xl">
        <MyAllEstimateDataTable />
      </Container>
    </div>
  );
}
