import { useEffect, useMemo, useState } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import {
  Box,
  Button,
  IconButton,
  Tooltip,
  Paper,
  TextField,
} from "@mui/material";
import axios from "axios";
import { Link, useSearchParams, useLocation } from "react-router-dom";
import { FileDownload, Preview } from "@mui/icons-material";
import { API_URL } from "../../config";
import { httpClient } from "../utils/HttpClient";
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import th from "date-fns/locale/th";

import "react-datepicker/dist/react-datepicker.css";

registerLocale("th", th);

const defaultPagination = {
  pageIndex: 0,
  pageSize: 10,
};

const MyAllEstimateDataTable = () => {
  //manage our own state for stuff we want to pass to the API
  const [data, setData] = useState([]);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [rowCount, setRowCount] = useState(10000);
  let [searchParams, setSearchParams] = useSearchParams();
  const [initialState, setInitialState] = useState(false);
  const location = useLocation()
  const thisLink = `${location.pathname}${location.search}`

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const handleReset = () => {
    setDateRange([null, null])
  }

  useEffect(() => {
    const where = searchParams.get("columnFilters");
    const search = searchParams.get("globalFilter");
    const sort = searchParams.get("sorting");
    const page = searchParams.get("pagination");
    const d1 = searchParams.get("startDate");
    const d2 = searchParams.get("endDate");

    if (where) {
      setColumnFilters(JSON.parse(where));
    }
    if (search) {
      setGlobalFilter(search);
    }
    if (sort) {
      setSorting(JSON.parse(sort));
    }
    if (page) {
      setPagination(JSON.parse(page));
      // table.setPageIndex(JSON.parse(page).pageIndex)
    }
    if (d1 && d2) {
      setDateRange([new Date(d1), new Date(d2)]);
    }

    setInitialState(true);
  }, []);

  //table state
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [pagination, setPagination] = useState(defaultPagination);

  const fetchData = async () => {
    if (!data.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }
    if (!initialState) {
      return null;
    }

    const url = new URL("/api/jobs/all-job", API_URL);
    url.searchParams.set("page", `${pagination.pageIndex}`);
    url.searchParams.set("limit", `${pagination.pageSize}`);
    url.searchParams.set("where", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("globalFilter", globalFilter ?? "");
    url.searchParams.set("sort", JSON.stringify(sorting ?? []));
    url.searchParams.set("startDate", startDate);
    url.searchParams.set("endDate", endDate);

    setSearchParams({
      ...(columnFilters.length > 0 && {
        columnFilters: JSON.stringify(columnFilters),
      }),
      ...(globalFilter !== "" && {
        globalFilter,
      }),
      ...(sorting.length > 0 && {
        sorting: JSON.stringify(sorting),
      }),
      ...((pagination.pageIndex !== defaultPagination.pageIndex ||
        pagination.pageSize !== defaultPagination.pageSize) && {
        pagination: JSON.stringify(pagination),
      }),
      ...(startDate && {
        startDate,
      }),
      ...(endDate && {
        endDate,
      }),
    });

    try {
      const response = await axios.get(url.href);
      const json = response.data;
      setData(json.rows);
      setRowCount(json.count);
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    }
    setIsError(false);
    setIsLoading(false);
    setIsRefetching(false);
  };

  const handleExportAllFilterData = () => {
    const url = new URL("/api/jobs/all-job-excel", API_URL);
    url.searchParams.set("page", `${pagination.pageIndex}`);
    url.searchParams.set("limit", `${pagination.pageSize}`);
    url.searchParams.set("where", JSON.stringify(columnFilters ?? []));
    url.searchParams.set("globalFilter", globalFilter ?? "");
    url.searchParams.set("sort", JSON.stringify(sorting ?? []));
    url.searchParams.set("startDate", startDate);
    url.searchParams.set("endDate", endDate);

    httpClient
      .get(url.href, {
        responseType: "blob", // important
      })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "export-fix.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };

  const handleExportData = () => {
    httpClient
      .get("/jobs/job-excel", {
        responseType: "blob", // important
      })
      .then((response) => {
        // create file link in browser's memory
        const href = URL.createObjectURL(response.data);

        // create "a" HTML element with href to file & click
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute("download", "export-fix.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();

        // clean up "a" element & remove ObjectURL
        document.body.removeChild(link);
        URL.revokeObjectURL(href);
      });
  };


  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "No.",
        enableEditing: false,
        size: 20,
        minSize: 20,
        grow: false,
        maxSize: 20,
      },
      {
        accessorKey: "desc",
        header: "รายละเอียด",
        enableEditing: false,
        minSize: 300,
        Cell: ({ renderedCellValue, row }) => (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            {row.original.JobImages && row.original.JobImages.length > 0 ? (
              <img
                alt="avatar"
                height={"50px"}
                src={`${API_URL}/uploads/images/${row.original.JobImages[0].fileName}`}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null; // prevents looping
                  currentTarget.src = "https://placehold.co/50?text=not_found";
                }}
                loading="lazy"
                style={{ height: "50px" }}
              />
            ) : (
              <img
                alt="avatar"
                height={"50px"}
                src={`https://placehold.co/50?text=image`}
                loading="lazy"
                style={{ height: "50px" }}
              />
            )}

            <span>{renderedCellValue}</span>
          </Box>
        ),
      },
      {
        accessorKey: "JobType.name",
        header: "ประเภทงาน",
        enableSorting: false,
      },
      {
        accessorKey: "JobType.JobGroup.name",
        header: "กลุ่มงาน",
        size: 100,
        enableSorting: false,
      },
      {
        accessorKey: "Branch.name",
        header: "สาขา",
        size: 100,
        grow: false,
        enableSorting: false,
      },
      {
        accessorKey: "fullName",
        header: "ผู้แจ้ง",
        enableSorting: false,
        Cell: ({ row }) => (
          <>
            {row.original.User.firstName} {row.original.User.lastName}
          </>
        ),
      },
      {
        accessorKey: "status",
        header: "สถานะ",
        filterVariant: "select",
        filterSelectOptions: [
          "งานใหม่",
          "งานใหม่(เปลี่ยนหมวด)",
          "ผู้ประเมินตรวจรับงาน",
          "งานโครงการ",
          "ส่งช่างภายใน",
          "ส่งช่างภายนอก",
          "ส่งช่างภายนอก (KDDS)",
          "สั่งอะไหล่",
          "KDDS ดำเนินการ",
          "รอตรวจรับงาน",
          "เสร็จสิ้น",
          "งานตีกลับ",
          "ยกเลิกงาน",
        ],
      },
      {
        accessorFn: (row) => new Date(row.createdAt),
        id: "createdAt",
        header: "วันที่แจ้งซ่อม",
        Cell: ({ cell }) => new Date(cell.getValue()).toLocaleString(),
        enableColumnFilter: false
        // filterVariant: "date",
        // enableEditing: false,
        // muiFilterDatePickerProps: {
        //   disableFuture: true,
        // },
      },
    ],
    []
  );

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    columnFilters,
    globalFilter,
    pagination.pageIndex,
    pagination.pageSize,
    sorting,
    initialState,
    dateRange
  ]);

  const table = useMaterialReactTable({
    columns,
    data,
    // layoutMode: "grid-no-grow",
    getRowId: (row) => row.id,
    createDisplayMode: "modal", //default ('row', and 'custom' are also available)
    editDisplayMode: "modal", //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    positionPagination: "both",
    enableGlobalFilter: false,
    initialState: { showColumnFilters: true },
    manualFiltering: true,
    manualPagination: true,
    manualSorting: true,
    muiToolbarAlertBannerProps: isError
      ? {
        color: "error",
        children: "Error loading data",
      }
      : undefined,
    muiTableContainerProps: {
      sx: {
        minHeight: "500px",
      },
    },
    muiBottomToolbarProps: {
      sx: {
        minHeight: "5rem",
      },
    },
    muiTableBodyProps: {
      sx: {
        //stripe the rows, make odd rows a darker color
        "& td:nth-of-type(odd)": {
          backgroundColor: "#f5f5f5",
        },
      },
    },
    muiTableBodyCellProps: {
      sx: {
        borderRight: "2px solid #e0e0e0", //add a border between columns
      },
    },
    enableFullScreenToggle: false,
    renderRowActions: ({ row, table }) => (
      <Box sx={{ display: "flex", gap: "1rem" }}>
        <Tooltip title="ดูเพิ่มเติม">
          <Link to={`/job/${row.original.id}`} state={{ backUrl: thisLink }}>
            <IconButton
            // onClick={() => navigate(`/job/${row.original.id}`)}
            >
              <Preview />
            </IconButton>
          </Link>
        </Tooltip>
      </Box>
    ),

    positionActionsColumn: "first",
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    onSortingChange: setSorting,
    autoResetPageIndex: false,
    rowCount,
    state: {
      columnFilters,
      globalFilter,
      isLoading,
      pagination,
      showAlertBanner: isError,
      showProgressBars: isRefetching,
      sorting,
    },
  });

  return (
    <Paper>
      <Paper sx={{ textAlign: "center", padding: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
        <div style={{ flex: 5 }}>
          <DatePicker
            selectsRange={true}
            startDate={startDate}
            endDate={endDate}
            locale="th"
            dateFormat="dd/MM/yyyy"
            showMonthDropdown
            showYearDropdown
            maxDate={new Date()}
            customInput={<TextField fullWidth variant='outlined' label="ช่วงวันที่สร้างงาน" />}
            onChange={(update) => {
              setDateRange(update);
            }}
            withPortal
          />
        </div>
        <div style={{ flex: 1 }}>
          <Button variant="outlined" size='small' onClick={handleReset} color="error">
            รีเซ็ต
          </Button>
        </div>
        <Button
          //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={handleExportData}
          startIcon={<FileDownload />}
        >
          Export งานทั้งหมด
        </Button>
        <Button
          disabled={table.getPrePaginationRowModel().rows.length === 0}
          //export all rows, including from the next page, (still respects filtering and sorting)
          onClick={() => handleExportAllFilterData()}
          startIcon={<FileDownload />}
        >
          Export งานที่ฟิลเตอร์
        </Button>
      </Paper>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        {initialState && <MaterialReactTable table={table} />}
      </LocalizationProvider>
    </Paper>
  );
};

export default MyAllEstimateDataTable;
