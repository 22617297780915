import {
  Box,
  Container,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  MenuList,
  MenuItem,
  ListItemText,
  Divider,
  Paper,
  ListItemIcon,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import { httpClient } from "../utils/HttpClient";
import { useLiffContext } from "../../LiffContext";
import { useNavigate } from "react-router-dom";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import TopMenu from "../TopMenu/TopMenu";

export default function Users() {
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(true);
  const [searchEmp, setSearchEmp] = useState("");
  const [queryData, setQueryData] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      // const profileLine = await liffFn.liff.getAccessToken()
      // await setProfile(profileLine)
      const result = await httpClient.get(`user/get-all`);
      if (result.data.status) {
        // const resultData = result.data.allUser;
        // console.log(resultData);
        setData(result.data);
        setPage(1);
        // setNoti(result.data.noti);
        setOpen(false);
      }
    })();
  }, []);

  const handleSearch = async () => {
    setOpen(true);
    const result = await httpClient.get(`user/get-all?search=${searchEmp}`);
    if (result.data.status) {
      // console.log(resultData);
      setData(result.data);
      setPage(1);
      // setNoti(result.data.noti);
      setOpen(false);
    }
  };

  const loadMore = async () => {
    setOpen(true);

    const result = await httpClient.get(
      `user/get-all?search=${searchEmp}&page=${page + 1}`
    );
    if (result.data.status) {
      // console.log(resultData);
      setData((oldData) => {
        const { allUser, ...newData } = result.data;
        return {
          allUser: [...oldData.allUser, ...allUser],
          ...newData,
        };
      });
      // setNoti(result.data.noti);
      await setPage((oldPage) => oldPage + 1);
      setOpen(false);
    }
  };

  return (
    <>
    <TopMenu back="/home" name="User ใน ระบบ" />
    <br />
      <Container component="main" maxWidth="xs" >
        <Box
          sx={{
            // minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            // alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <Paper sx={{ width: 345 }}>
            <Grid container sx={{ m: 1 }} alignItems="center" spacing={1}>
              <Grid item xs={8}>
                <TextField
                  variant="outlined"
                  fullWidth
                  size="small"
                  label="รหัสพนักงาน หรือชื่อ นามสกุล"
                  value={searchEmp}
                  onChange={(e) => {
                    e.preventDefault();
                    setSearchEmp(e.target.value);
                  }}
                ></TextField>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  size="meduim"
                  fullWidth
                  onClick={() => handleSearch()}
                >
                  ค้นหา
                </Button>
              </Grid>
            </Grid>

            <MenuList>
              {data
                ? data.allUser.map((user, key) => (
                    <div key={key}>
                      <MenuItem
                        onClick={() =>
                          navigate(`/edit-user/${user.employeeId}`)
                        }
                      >
                        <ListItemIcon>
                          <PersonIcon
                            fontSize="small"
                            color={user.lineUserId ? "success" : "seccondary"}
                          />
                        </ListItemIcon>
                        {!user.isActive ? (
                          <ListItemText sx={{ textDecoration: "line-through" }}>
                            {user.employeeId} {user.fullName}
                          </ListItemText>
                        ) : (
                          <ListItemText>
                            {user.employeeId} {user.fullName}
                          </ListItemText>
                        )}
                      </MenuItem>
                      <Divider />
                    </div>
                  ))
                : null}
            </MenuList>
            {data && (
              <Typography variant="body1" sx={{ my: 1 }} align="center">
                {`จำนวน ${data.allUser.length} จากทั้งหมด ${data.total}`}
              </Typography>
            )}
            {data && data.allUser.length < data.total ? (
              <div style={{ textAlign: "center" }}>
                <Button
                  sx={{ mb: 1 }}
                  variant="contained"
                  size="small"
                  onClick={() => loadMore()}
                >
                  โหลดเพิ่ม
                </Button>
              </div>
            ) : null}
          </Paper>
        </Box>
      </Container>
    </>
  );
}
