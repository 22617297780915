import axios from "axios";
import join from "url-join";
import { API_URL } from "../../config";
import { redirect } from "react-router-dom";
import Swal from "sweetalert2";

const isAbsoluteURLRegex = /^(?:\w+:)\/\//;

axios.interceptors.request.use(async (config) => {
  if (!isAbsoluteURLRegex.test(config.url)) {
    config.url = join(API_URL,"/api", config.url);
  }

  const userToken = localStorage.getItem("jwt");
  if (userToken) {
    config.headers = { Authorization: `Bearer ${userToken}` };
  }
  config.timeout = 90000; // 90 Second
  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    // const { dispatch } = store;
    console.log(JSON.stringify(error, undefined, 2));
    Swal.fire({
      title: "ขออภัย!",
      text: `เกิดข้อผิดพลาด กรุณาลองใหม่อีกครัั้งในภายหลัง หรือติดต่อเจ้าหน้าที่ ${error && error.message?error.message:""}`,
      icon: "error",
      confirmButtonText: "ตกลง",
    });

    if (error.response.status === 401) {
      const thisPath = window.location.pathname;
      return redirect(`/login?oldUrl=${thisPath}`);
    }

    if (axios.isCancel(error)) {
      return Promise.reject(error);
    } else if (!error.response) {
      return Promise.reject({
        code: "NOT_CONNECT_NETWORK",
        message: "Cannot connect to server, Please try again.",
      });
    }
    return Promise.reject(error);
  }
);

export const httpClient = axios;
