import React from "react";
import Typography from "@mui/material/Typography";
import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  DialogTitle,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Pagination,
  Toolbar,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { useEffect, useState } from "react";
import LoadingBackdrop from "../../Loading";
import { ArrowBackIos, Edit } from "@mui/icons-material";
import { httpClient } from "../utils/HttpClient";
import { useNavigate } from 'react-router-dom'
import TopMenu from "../TopMenu/TopMenu";

function Team(props) {
  const { onClose, selectedValue, open } = props;
  const navigate = useNavigate();


  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value) => {
    onClose(value);
  };

  if (!selectedValue) {
    return null;
  }

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>
        จัดการสมาชิกทีม {selectedValue.name}
      </DialogTitle>
      <List sx={{ pt: 0 }}>
        <ListItem disableGutters>
          <ListItemButton
            onClick={() => navigate(`/team-member/member/${selectedValue.id}`)}
          >
            <ListItemAvatar>
              <Avatar >
                <Edit />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"เพิ่มสมาชิกทีม"} />
          </ListItemButton>
        </ListItem>

        <ListItem disableGutters>
          <ListItemButton
            onClick={() => navigate(`/team-member/head/${selectedValue.id}`)}
          >
            <ListItemAvatar>
              <Avatar >
                <Edit />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"เพิ่มหัวหน้าทีม"} />
          </ListItemButton>
        </ListItem>

        <ListItem disableGutters>
          <ListItemButton
            onClick={() => navigate(`/team/${selectedValue.id}`)}
          >
            <ListItemAvatar>
              <Avatar >
                <Edit />
              </Avatar>
            </ListItemAvatar>
            <ListItemText primary={"ดูสมาชิกในทีม"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Dialog>
  );
}

const TeamManagement = () => {
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [selectedValue, setSelectedValue] = React.useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = (value) => {
    setOpen(false);
    setSelectedValue(value);
  };

  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false);

  const getData = async (pageNew) => {
    const result = await httpClient.get("admin/team");
    setData(result.data);
  };

  useEffect(() => {
    getData()
  }, []);

  if (!data) {
    return <LoadingBackdrop />;
  }

  return (
    <div>
      <TopMenu back="/home" name="ทีมทั้งหมด" />
      {isLoading && <LoadingBackdrop  />}
      <Container>
        <Box display="flex">
          <Box m="auto">
            <Paper elevation={5} sx={{mt:10,mb:3,width:345}} className="mt-10 mb-3 w-[345px]">
              <div className="flex justify-end">
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => navigate("/new-team")}
                  className="bg-tkk text-[#ffffff] mt-3 mr-3"
                >
                  สร้างทีมใหม่
                </Button>
              </div>
              <br />
              <div style={{padding:5}}>
                <Typography variant="h5" align="center" className="my-3">
                  จัดการทีม
                </Typography>

                <MenuList className="mt-1">
                  {data ? (
                    data.length > 0 ? (
                      data.map((team, key) => (
                        <div key={key}>
                          <MenuItem
                            onClick={() => {
                              setSelectedValue(team);
                              handleClickOpen();
                            }}
                          >
                            <ListItemText>
                              {team.name}
                            </ListItemText>
                          </MenuItem>
                          <Divider />
                        </div>
                      ))
                    ) : (
                      <Typography align="center">
                        ไม่พบข้อมูล
                      </Typography>
                    )
                  ) : (
                    <Typography align="center">กำลังโหลด</Typography>
                  )}
                </MenuList>
              </div>
            </Paper>
          </Box>
        </Box>
      </Container>
      <Team
        selectedValue={selectedValue}
        open={open}
        onClose={handleClose}
      />
    </div>
  );
};

export default TeamManagement;
