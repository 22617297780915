import { Avatar, Grid } from "@mui/material";
import React, { useState } from "react";
// import Lightbox from "react-spring-lightbox";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";

export default function JobLogCard({ images }) {
  const [isOpen, setIsOpen] = useState(false);

  const [currentImageIndex, setCurrentIndex] = useState(0);

  const gotoPrevious = () =>
    currentImageIndex > 0 && setCurrentIndex(currentImageIndex - 1);

  const gotoNext = () =>
    currentImageIndex + 1 < images.length &&
    setCurrentIndex(currentImageIndex + 1);
  return (
    <>
      <Lightbox
        open={isOpen}
        close={() => setIsOpen(false)}
        slides={images}
        plugins={[Zoom]}
        animation={{ zoom: 500 }}
        carousel={{
          finite: true,
        }}
        controller={{ closeOnPullDown: true, closeOnBackdropClick: true }}
      />
      <Grid container spacing={1} justifyContent="center">
        {images.map((image, key) => (
          <Grid item xs={4} key={key}>
            <div
              onClick={() => {
                setCurrentIndex(key);
                setIsOpen(true);
              }}
            >
              <Avatar
                alt="รูปงานซ่อม"
                variant="square"
                sx={{ width: "100%", height: "100px" }}
                src={image.src}
              />
            </div>
          </Grid>
        ))}
      </Grid>
    </>
  );
}
