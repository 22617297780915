import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import { useNavigate } from 'react-router-dom'
import { httpClient } from "../utils/HttpClient";


const schema = yup.object({
  name: yup.string().required("ต้องกรอกช่องนี้"),
});

export default function TeamForm({ setIsLoading }) {
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const navigate = useNavigate()

  const handleOnSubmit = async (evt) => {
    try {
      setIsLoading(true);
      const createTeam = await httpClient.post("admin/team", { name: evt.name })
      if(!createTeam.data){
        throw new Error("No data");
      }
      reset();
      setIsLoading(false);
      Swal.fire({
        icon: "success",
        title: "ส่งข้อมูลสำเร็จ",
        confirmButtonText: "ยืนยัน",
        confirmButtonColor: "#F21826",
        showCancelButton: false,
      }).then(() => {
        navigate("/teams")
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: error.response?.data?.message || "มีบางอย่างผิดพลาด กรูณาลองใหม่ในภายหลัง!",
        confirmButtonText: "ยืนยัน",
        confirmButtonColor: "#F21826",
        showCancelButton: false,
      });
    }
  };

  return (
    <div>
      <Box component="form" onSubmit={handleSubmit(handleOnSubmit)}>
        <Controller
          control={control}
          name="name"
          defaultValue=""
          render={({ field }) => (
            <div style={{ margin: 3 }}>
              <TextField
                {...field}
                fullWidth
                error={!!errors.name}
                helperText={errors?.name?.message}
                variant="filled"
                label="ชื่อทีม *"
              />
            </div>
          )}
        />


        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          sx={{ my: 1 }}
        >
          ยืนยัน
        </Button>
        <Button
          fullWidth
          variant="outlined"
          color="primary"
          onClick={() => navigate("/teams")}
        >
          ยกเลิก
        </Button>
      </Box>
    </div>
  );
}
