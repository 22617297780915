import React, { useEffect } from 'react'
import { httpClient } from '../utils/HttpClient'

export default function ExportExcel() {
    useEffect(()=>{
        httpClient.get("/jobs/job-excel",{
            responseType: 'blob', // important
        }).then((response) => {
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);
        
            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', 'export-fix.xlsx'); //or any other extension
            document.body.appendChild(link);
            link.click();
        
            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    },[])
  return (
    <div>Loading...</div>
  )
}
