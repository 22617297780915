import React, { useState } from 'react'
import OverdueBarChart from './OverdueBarChart'
import OverdueTypeBarChart from './OverdueTypeBarChart'
import StackGroupBar from './StackGroupBar'
import { Container, Paper, TextField, Button } from '@mui/material'
import DatePicker from "react-datepicker";
import { registerLocale } from "react-datepicker";
import th from "date-fns/locale/th";
import TopMenu from "../TopMenu/TopMenu";
import "react-datepicker/dist/react-datepicker.css";

registerLocale("th", th);



export default function Dashboard() {
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const handleReset = () => { 
    setDateRange([null, null])
   }
  return (
    <div>
      <TopMenu back="/home" name="กราฟ" />
      <Container >
        <br />
        <Paper sx={{ textAlign: "center", padding: 5, display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 2 }}>
          <div style={{ flex: 5 }}>
            <DatePicker
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              locale="th"
              dateFormat="dd/MM/yyyy"
              showMonthDropdown
              showYearDropdown
              maxDate={new Date()}
              customInput={<TextField fullWidth variant='outlined' label="ช่วงวันที่สร้างงาน" />}
              onChange={(update) => {
                setDateRange(update);
              }}
              withPortal
            />
          </div>
          <div style={{ flex: 1 }}>
            <Button variant="outlined" size='small' onClick={handleReset} color="error">
              รีเซ็ต
            </Button>
          </div>
        </Paper>
      </Container>
      <StackGroupBar dateRange={dateRange} />
      <OverdueBarChart dateRange={dateRange} />
      <OverdueTypeBarChart dateRange={dateRange} />
    </div>
  )
}
