import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { API_URL } from "../../config";
import { Chip } from "@mui/material";
import { format, utcToZonedTime } from "date-fns";

const Widget = styled("div")(({ theme }) => ({
  padding: 16,
  borderRadius: 16,
  width: 345,
  maxWidth: "100%",
  margin: "auto",
  position: "relative",
  zIndex: 1,
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(0,0,0,0.6)" : "rgba(255,255,255,0.8)",
  backdropFilter: "blur(40px)",
}));

const CoverImage = styled("div")({
  width: 80,
  height: 80,
  objectFit: "cover",
  overflow: "hidden",
  flexShrink: 0,
  borderRadius: 8,
  backgroundColor: "rgba(0,0,0,0.08)",
  "& > img": {
    width: "100%",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
  },
});

function SplitTime(numberOfHours){
  var Days=Math.floor(numberOfHours/24);
  var Remainder=numberOfHours % 24;
  var Hours=Math.floor(Remainder);
  var Minutes=Math.floor(60*(Remainder-Hours));
  return(`${Days>0?` ${Days} วัน`:""}${Hours>0?` ${Hours} ชั่วโมง`:""}${Minutes>0?` ${Minutes} นาที`:""}`)
}

export default function JobCard({ job }) {
  return (
    <Box sx={{ width: "100%", overflow: "hidden", mt: 1 }}>
      <Widget>
        <Typography
          variant="body2"
          component={"div"}
          noWrap
          sx={{ mb: 1 }}
          align="right"
        >
          <Chip
            label={job.Branch.name}
            sx={{
              maxWidth: "40px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            size="small"
          />{" "}
          <Chip
            label={job.JobType.name}
            size="small"
            sx={{
              maxWidth: "150px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          />{" "}
          <Chip
            color="primary"
            sx={{
              maxWidth: "120px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            label={job.status}
            size="small"
          />
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <CoverImage>
            {job.JobImages && job.JobImages.length > 0 ? (
              <img
                alt="รูปงานแจ้งซ่อม"
                src={`${API_URL}/uploads/images/${job.JobImages[0].fileName}`}
              />
            ) : (
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ mt: "28px" }}
                align="center"
              >
                ไม่มีรูป
              </Typography>
            )}
          </CoverImage>
          <Box sx={{ ml: 1.5, minWidth: 0 }}>
            <Typography
              variant="subtitle1"
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                display: "-webkit-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical",
              }}
            >
              {job.desc}
            </Typography>
          </Box>
        </Box>
        <Typography
          noWrap
          align="center"
          letterSpacing={-0.25}
          variant="caption"
          component={"div"}
          sx={{ mt: 1 }}
        >
          ผู้แจ้ง: {job.User.firstName} {job.User.lastName} | วันที่แจ้ง:{" "}
          {format(new Date(job.createdAt), "dd/MM/yyyy HH:mm")}
        </Typography>
        <Typography
          noWrap
          align="center"
          letterSpacing={-0.25}
          variant="caption"
          component={"div"}
          // sx={{ mt: 1 }}
        >
          #{job.fullId}{job.level && ` | ระดับงาน: ${job.level} (ประมาณเวลา ${SplitTime(job.JobType.JobGroup[`level${job.level}Time`])})`}
        </Typography>
      </Widget>
    </Box>
  );
}
