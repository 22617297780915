
import { useEffect, useState } from "react";
// import LoadingBackdrop from "@/components/LoadingBackdrop";
import { Avatar, Box, Container, Grid, Paper, Typography } from "@mui/material";
import axios from "axios";
import TeamForm from "./TeamForm";
import LoadingBackdrop from '../../Loading'


const NewTeam = ({
  liff,
  liffError,
  user,
  profile,
  token
}) => {
  const [isOpen, setIsOpen] = useState(false)


  return (
    <div>
      {isOpen && <LoadingBackdrop />}
      <Container>
        <Box display="flex">
          <Box m="auto">
            <Paper elevation={5} sx={{mt:10 , mb:3 , width:345}} >
              <div style={{padding:5}}>
                <Typography variant="h5" align="center" sx={{my:3}}>
                  สร้างทีมใหม่
                </Typography>
                <br />
                <TeamForm setIsLoading={setIsOpen} />
              </div>
            </Paper>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default NewTeam;
