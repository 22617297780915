import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  Container,
  Divider,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { useLiffContext } from "../../LiffContext";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { httpClient } from "../utils/HttpClient";
import Swal from "sweetalert2";
import { API_URL, ROLE_NAME } from "../../config";
import { format } from "date-fns";

import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

import FormData from "form-data";
import { compressImage } from '../utils/compressImage'

import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";

const defaultData = {
  detail: "",
  branch: null,
  jobType: null,
  jobStatus: {
    id: "เปลี่ยนหมวดงาน",
    label: "เปลี่ยนหมวดงาน",
  },
};

export default function ChangeJobTypeAction({ Init, successCallBackUrl }) {
  const { liffFn } = useLiffContext();
  const [images, setImages] = useState([]);
  const navigate = useNavigate();

  const [data, setData] = useState(defaultData);
  const [open, setOpen] = useState(false);
  const [initData, setInitData] = useState();

  const changeData = (e) => {
    setData((oldData) => {
      let newData = { ...oldData };
      newData[e.target.name] = e.target.value;
      return newData;
    });
  };

  const handleUpdate = async () => {
    setOpen(true);
    let data2 = new FormData();
    data2.append("data", JSON.stringify(data));
    data2.append("countFile", files.length);
    let indexKey = 0
    for (const element of files) {
      const tinyImage = await compressImage(element.file)
      data2.append(`file${indexKey}`, tinyImage);
      indexKey += 1
    }

    const result = await httpClient.post(
      `/jobs/change-job-type/${Init.job.id}`,
      data2,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    setOpen(false);
    if (result.data.status) {
      Swal.fire({
        title: "สำเร็จ!",
        text: `อัพเดทเรียบร้อยแล้ว`,
        icon: "success",
        confirmButtonText: "ตกลง",
      }).then(() => {
        navigate(successCallBackUrl);
      });
    } else {
      Swal.fire({
        title: "ขออภัย!",
        text: `กรุณาลองใหม่อีกครัั้งในภายหลัง หรือติดต่อเจ้าหน้าที่`,
        icon: "error",
        confirmButtonText: "ตกลง",
      });
    }
  };

  const [files, setFiles] = useState([]);
  const [imageSrc, setImageSrc] = useState(undefined);
  const updateFiles = (incommingFiles) => {
    setFiles(incommingFiles);
  };
  const onDelete = (id) => {
    setFiles(files.filter((x) => x.id !== id));
  };
  const handleSee = (imageSource) => {
    setImageSrc(imageSource);
  };

  const checkDisabled = () => {
    if (
      data &&
      Init &&
      data.branch != "" &&
      data.jobType != "" &&
      (data.branch != Init.job.branchId ||
        (data.jobType && data.jobType.id != Init.job.jobTypeId))
    ) {
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    (async () => {
      setOpen(true);
      const result2 = await httpClient.get(`jobs/init`);
      if (result2.data.status) {
        setInitData(result2.data);
        setData((oldData) => {
          return {
            ...oldData,
            branch: {
              id: Init.job.Branch.id,
              name: Init.job.Branch.name,
            },
            jobType: {
              id: Init.job.jobTypeId,
              label: Init.job.JobType.name,
            },
          };
        });
        setOpen(false);
      }
    })();
  }, []);

  return (
    <div>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Divider textAlign="left" sx={{ my: 1 }}>
        <Chip
          sx={{ lineHeight: 1.8 }}
          label={<strong>สำหรับเปลี่ยนหมวดงาน</strong>}
        />
      </Divider>
      {initData && data && data.branch != "" && data.jobType != "" && (
        <>
          <Autocomplete
            disablePortal
            noOptionsText="ไม่มี"
            value={data.jobStatus}
            isOptionEqualToValue={(option, value) => {
              return option.id == value.id;
            }}
            getOptionLabel={(option) => option.label}
            onChange={(e, newValue) => {
              setData((oldData) => {
                let newData = { ...oldData };
                newData["jobStatus"] = newValue;
                return newData;
              });
            }}
            options={[
              {
                id: "เปลี่ยนหมวดงาน",
                label: "เปลี่ยนหมวดงาน",
              },
              {
                id: "ยกเลิกงาน",
                label: "ยกเลิกงาน",
              },
            ]}
            name="jobStatus"
            renderInput={(params) => (
              <TextField {...params} fullWidth label="สถานะงาน *" />
            )}
          />

          {data.jobStatus.id === "ยกเลิกงาน" ? null : (
            <>
              <Autocomplete
                disablePortal
                sx={{ mt: 2 }}
                noOptionsText="ไม่มีประเภทงานซ่อมที่ค้นหา"
                value={data.jobType}
                isOptionEqualToValue={(option, value) => {
                  return option.id == value.id;
                }}
                getOptionLabel={(option) => option.label}
                onChange={(e, newValue) => {
                  setData((oldData) => {
                    let newData = { ...oldData };
                    newData["jobType"] = newValue;
                    return newData;
                  });
                }}
                options={initData.jobType}
                name="jobType"
                renderInput={(params) => (
                  <TextField {...params} fullWidth label="ประเภทงานซ่อม *" />
                )}
              />
              <Autocomplete
                disablePortal
                sx={{ mt: 2 }}
                noOptionsText="ไม่มีสาขาที่ค้นหา"
                value={data.branch}
                isOptionEqualToValue={(option, value) => {
                  return option.id == value.id;
                }}
                getOptionLabel={(option) => option.name}
                onChange={(e, newValue) => {
                  setData((oldData) => {
                    let newData = { ...oldData };
                    newData["branch"] = newValue;
                    return newData;
                  });
                }}
                options={initData.branch}
                name="branch"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    fullWidth
                    label="สาขาที่ต้องการแจ้งซ่อม *"
                  />
                )}
              />
            </>
          )}
        </>
      )}

      <TextField
        variant="outlined"
        fullWidth
        label="รายละเอียด"
        size="small"
        value={data.detail}
        name="detail"
        multiline
        rows={4}
        sx={{ mt: 2 }}
        onChange={(e) => {
          changeData(e);
        }}
      />
      <Dropzone
        onChange={updateFiles}
        value={files}
        // onClean
        accept={"image/*"}
        label={"กดที่นี่เพื่อเลือกรูปประกอบ"}
        minHeight={"195px"}
        maxHeight={"500px"}
        disableScroll
        footer={false}
        maxFiles={5}
        style={{ marginTop: "15px" }}
      >
        {files.length > 0 &&
          files.map((file) => (
            <FileItem
              {...file}
              key={file.id}
              onDelete={onDelete}
              alwaysActive
              onSee={handleSee}
              hd
              preview
              //   info
              resultOnTooltip
            />
          ))}
      </Dropzone>
      {imageSrc && (
        <FullScreenPreview
          imgSource={imageSrc}
          openImage={imageSrc}
          onClose={(e) => handleSee(undefined)}
        />
      )}

      <Button
        disabled={checkDisabled()}
        onClick={() => handleUpdate()}
        variant="contained"
        sx={{ my: 2 }}
        fullWidth
        size="small"
      >
        บันทึก
      </Button>
    </div>
  );
}
