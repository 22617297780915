import {
  Box,
  Container,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  MenuList,
  MenuItem,
  ListItemText,
  Divider,
  Paper,
  ListItemIcon,
  Grid,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import { httpClient } from "../utils/HttpClient";
import { useLiffContext } from "../../LiffContext";
import { useNavigate } from "react-router-dom";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import JobCard from "../MyJob/JobCard";
import TopMenu from "../TopMenu/TopMenu";

export default function MyRepair() {
  const [data, setData] = useState();
  const [page, setPage] = useState(1);
  const [open, setOpen] = useState(true);
  const [queryData, setQueryData] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    (async () => {
      // const profileLine = await liffFn.liff.getAccessToken()
      // await setProfile(profileLine)
      const result = await httpClient.get(`jobs/my-repair`);
      if (result.data.status) {
        const resultData = result.data;
        setData(result.data);
        setPage(1);
        // setNoti(result.data.noti);
        setOpen(false);
      }
    })();
  }, []);

  const loadMore = async () => {
    setOpen(true);

    const result = await httpClient.get(`jobs/my-repair?page=${page + 1}`);
    if (result.data.status) {
      setData((oldData) => {
        const { allJob, ...newData } = result.data;
        return {
          allJob: [...oldData.allJob, ...allJob],
          ...newData,
        };
      });
      // setNoti(result.data.noti);
      await setPage((oldPage) => oldPage + 1);
      setOpen(false);
    }
  };

  return (
    <>
    <TopMenu back="/home" name="งานซ่อมที่ได้รับมอบหมายให้ซ่อม" />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <br />
      {data
        ? data.allJob.map((job, key) => (
            <div key={key} onClick={()=>{
              navigate(`/job/${job.id}`)
            }}>
              <JobCard job={job} />
            </div>
          ))
        : null}
      <br />
      {data && (
        <Typography variant="body1" sx={{ my: 1 }} align="center">
          {`จำนวน ${data.allJob.length} จากทั้งหมด ${data.total}`}
        </Typography>
      )}
      {data && data.allJob.length < data.total ? (
        <div style={{ textAlign: "center" }}>
          <Button
            sx={{ mb: 1 }}
            variant="contained"
            size="small"
            onClick={() => loadMore()}
          >
            โหลดเพิ่ม
          </Button>
        </div>
      ) : null}
    </>
  );
}
