import {useNavigate , useParams} from 'react-router-dom'
import { useEffect, useState } from "react";
import LoadingBackdrop from "../../Loading";
import {
  Avatar,
  Box,
  Chip,
  Container,
  Grid,
  Paper,
  Typography,
} from "@mui/material";
import axios from "axios";
import EditTeamMemberForm from "./EditTeamMember";

const TeamMember = () => {
  const { id , role } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div>
      {isLoading && <LoadingBackdrop />}
      <Container>
        <Box display="flex">
          <Box m="auto">
            <Paper elevation={5} sx={{mt:10,mb:3,width:345}}>
              <div style={{padding:5}}>
                <Typography variant="h5" align="center" sx={{my:3}}>
                  {role==="head"?"เพิ่มหัวหน้าทีม":"เพิ่มสมาชิกในทีม"}
                </Typography>

                <EditTeamMemberForm
                  id={`${id}`}
                  role={role}
                  setIsLoading={setIsLoading}
                />
              </div>
            </Paper>
          </Box>
        </Box>
      </Container>
    </div>
  );
};

export default TeamMember;
