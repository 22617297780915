import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Backdrop, Button, Card, CardContent, Chip } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
// import { useLiff } from "react-liff";
import liff from "@line/liff";
import { LIFF_ID } from "../../config";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLiffContext } from "../../LiffContext";

function Copyright(props) {
  return (
    <Typography
      variant="body2"
      color="text.secondary"
      align="center"
      {...props}
    >
      {"Copyright © "}
      <Link color="inherit" href="https://fac.toyotakhonkaen.com/">
        FM TKK Group
      </Link>{" "}
      {new Date().getFullYear()}
      {"."}
    </Typography>
  );
}

export default function Login() {
  const [profile, setProfile] = React.useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const oldUrl = searchParams.get("oldUrl");
  const navigate = useNavigate();
  const { setLiff } = useLiffContext();

  React.useEffect(() => {
    liff
      .init({
        liffId: LIFF_ID, // Use own liffId
      })
      .then(() => {
        // Start to use liff's api
        isLogin();
      })
      .catch((err) => {
        // Error happens during initialization
        console.log(err.code, err.message);
      });

    const isLogin = async () => {
      if (!(await liff.isLoggedIn())) {
        await liff.login();
      } else {
        await setLiff({
          isLogin: true,
          liff: liff,
        });
        const token = await liff.getAccessToken();
        localStorage.setItem("jwt",token)
        if(oldUrl && oldUrl !== "/"){
          navigate(oldUrl)
        } else{
          navigate("/home")
        }
      }
    };
  }, [liff]);

  return (
    <>
      <div
        style={{
          backgroundImage: "url(/tkk-banner.jpeg)",
          filter: "blur(8px)",
          height: "100vh",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      ></div>
      <Backdrop
        open
        sx={{ color: "#ffffff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
}
