import {
  Box,
  Card,
  Container,
  CardContent,
  TextField,
  Typography,
  Button,
  Backdrop,
  CircularProgress,
  MenuList,
  MenuItem,
  ListItem,
  ListItemText,
  Divider,
  Paper,
  ListItemIcon,
  Switch,
  FormControl,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import PersonIcon from "@mui/icons-material/Person";
import { httpClient } from "../utils/HttpClient";
import { registerLocale } from "react-datepicker";
import th from "date-fns/locale/th";
import { useLiffContext } from "../../LiffContext";
import sub from "date-fns/sub";

import Autocomplete from "@mui/material/Autocomplete";

import DatePicker from "react-datepicker";
import { format } from "date-fns";
import FormData from "form-data";

import { Dropzone, FileItem, FullScreenPreview } from "@dropzone-ui/react";
import TopMenu from "../TopMenu/TopMenu";
import LineFlexMessage from "../utils/LineFlexMessage";
import { LIFF_ID } from "../../config";
import {compressImage} from '../utils/compressImage'

registerLocale("th", th);

export default function NewJob() {
  const defaultData = {
    desc: "",
    branch: null,
    jobType: null,
    address: "",
  };
  const { liffFn } = useLiffContext();
  const [data, setData] = useState(defaultData);
  const [open, setOpen] = useState(false);
  const [init, setInit] = useState();
  const [files, setFiles] = useState([]);
  const [imageSrc, setImageSrc] = useState(undefined);
  const updateFiles = (incommingFiles) => {
    // console.log("incomming files", incommingFiles);
    setFiles(incommingFiles);
  };
  const onDelete = (id) => {
    setFiles(files.filter((x) => x.id !== id));
  };
  const handleSee = (imageSource) => {
    setImageSrc(imageSource);
  };
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      setOpen(true);
      const result2 = await httpClient.get(`jobs/init`);
      if (result2.data.status) {
        setInit(result2.data);
        setData((oldData) => {
          return {
            ...oldData,
            branch: {
              id: result2.data.user.dbUser.Branch.id,
              name: result2.data.user.dbUser.Branch.name,
            },
          };
        });
        // setInit(result2.data);
        setOpen(false);
      }
    })();
  }, []);

  const handleSubmit = async () => {
    // console.log(data);
    setOpen(true);

    let data2 = new FormData();
    data2.append("data", JSON.stringify(data));
    data2.append("countFile", files.length);
    let indexKey = 0
    for (const element of files) {
      const tinyImage = await compressImage(element.file)
      data2.append(`file${indexKey}`, tinyImage);
      indexKey += 1
    }
    const result = await httpClient.post("/jobs/new-job", data2, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    setOpen(false);
    if (result.data.status) {
      // const jobBranchName = data.branch.filter(
      //   (b) => b.id === data.branch
      // ).name;
      const message = LineFlexMessage(
        "งานใหม่",
        data.jobType.label,
        data.desc,
        `สาขา ${data.branch.name} ${data.address}`,
        format(new Date(), "dd/MM/yyyy HH:mm"),
        `${init.user.dbUser.firstName} ${init.user.dbUser.lastName}`,
        null,
        result.data.job.fullId,
        `https://liff.line.me/${LIFF_ID}?oldUrl=/job/${result.data.job.id}`
      );
      // console.log(message);
      liffFn.liff.sendMessages([message]);
      Swal.fire({
        title: "สำเร็จ!",
        text: `แจ้งซ่อม เรียบร้อยแล้ว`,
        icon: "success",
        confirmButtonText: "ตกลง",
      }).then(() => {
        navigate("/home");
      });
    } else {
      Swal.fire({
        title: "ขออภัย!",
        text: `กรุณาลองใหม่อีกครัั้งในภายหลัง หรือติดต่อเจ้าหน้าที่`,
        icon: "error",
        confirmButtonText: "ตกลง",
      });
      setOpen(false);
    }
  };
  const changeData = (e) => {
    setData((oldData) => {
      let newData = { ...oldData };
      newData[e.target.name] = e.target.value;
      return newData;
    });
  };

  return (
    <>
      <TopMenu back="/home" />
      <br />
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            // minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // justifyContent: "center",
          }}
        >
          <Backdrop
            sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          <Paper sx={{ width: 345 }}>
            <br />
            <Typography variant="h5" align="center">
              แจ้งงานซ่อมใหม่
            </Typography>
            <Typography variant="body1" color="textSecondary" align="center">
              กรุณาเพิ่มข้อมูลในช่องที่มีเครื่องหมาย * ให้ครบทุกช่อง
              จึงจะสามารถแจ้งซ่อมได้
            </Typography>
            <br />
            <div style={{ margin: "10px", paddingBottom: "10px" }}>
              {init && (
                <>
                  <Autocomplete
                    disablePortal
                    noOptionsText="ไม่มีประเภทงานซ่อมที่ค้นหา"
                    value={data.jobType}
                    getOptionLabel={(option) => option.label}
                    onChange={(e, newValue) => {
                      setData((oldData) => {
                        let newData = { ...oldData };
                        newData["jobType"] = newValue;
                        return newData;
                      });
                    }}
                    options={init.jobType}
                    name="jobType"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="ประเภทงานซ่อม *"
                      />
                    )}
                  />
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="รายละเอียดงานแจ้งซ่อม *"
                    value={data.desc}
                    name="desc"
                    multiline
                    rows={4}
                    sx={{ mt: 2 }}
                    onChange={(e) => {
                      changeData(e);
                    }}
                  />
                  <Autocomplete
                    disablePortal
                    sx={{ mt: 2 }}
                    noOptionsText="ไม่มีสาขาที่ค้นหา"
                    value={data.branch}
                    getOptionLabel={(option) => option.name}
                    onChange={(e, newValue) => {
                      setData((oldData) => {
                        let newData = { ...oldData };
                        newData["branch"] = newValue;
                        return newData;
                      });
                    }}
                    options={init.branch}
                    name="branch"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        label="สาขาที่ต้องการแจ้งซ่อม *"
                      />
                    )}
                  />
                  
                  <TextField
                    variant="outlined"
                    fullWidth
                    label="รายละเอียดที่อยู่เพิ่มเติม (ตึก ชั้น) *"
                    value={data.address}
                    name="address"
                    multiline
                    rows={4}
                    sx={{ mt: 2 }}
                    onChange={(e) => {
                      changeData(e);
                    }}
                  />
                </>
              )}


              <Dropzone
                onChange={updateFiles}
                value={files}
                // onClean
                accept={"image/*"}
                label={"กดที่นี่เพื่อเลือกรูปประกอบ"}
                minHeight={"195px"}
                maxHeight={"500px"}
                disableScroll
                footer={false}
                maxFiles={5}
                style={{ marginTop: "15px" }}
              >
                {files.length > 0 &&
                  files.map((file) => (
                    <FileItem
                      {...file}
                      key={file.id}
                      onDelete={onDelete}
                      alwaysActive
                      onSee={handleSee}
                      hd
                      preview
                      //   info
                      resultOnTooltip
                    />
                  ))}
              </Dropzone>
              <Typography variant="body1" component="div" >หมายเหตุ หากมีเลขสินทรัพย์กรุณาแนบรูปที่มีเลขสินทรัพย์ด้วย</Typography>
              {imageSrc && (
                <FullScreenPreview
                  imgSource={imageSrc}
                  openImage={imageSrc}
                  onClose={(e) => handleSee(undefined)}
                />
              )}

              <Button
                variant="contained"
                sx={{ mt: 2 }}
                onClick={() => handleSubmit()}
                fullWidth
                disabled={
                  data.branch === "" ||
                  data.desc === "" ||
                  data.address === "" ||
                  data.jobType === null ||
                  data.jobType === ""
                }
              >
                แจ้งซ่อม
              </Button>
              <Button
                variant="outlined"
                sx={{ mt: 1 }}
                onClick={() => navigate("/home")}
                fullWidth
              >
                ย้อนกลับ
              </Button>
            </div>
          </Paper>
        </Box>
      </Container>
    </>
  );
}
